
.contact__wrap span {
  min-width: fit-content;
}
.header__topbar_wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 3rem;
  background-color: #131466;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

.header__topbar_left {
  flex: 1;
  color: #fff;
  white-space: nowrap;
}
.header__topbar_leftcontent {
  font-size: 15px;
  width: fit-content;
}

.header__topbar_right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
}

.header__topbar_register {
  margin-right: 10px;
  padding: 3px 17px;
  border: none;
  background-color: #fff;
  color: #131466;
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
}

.cross {
  width: 20px;
  height: 20px;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.header__toplogo_info_wrap {
  display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 1360px;
    margin: 0 auto;
    align-items: center;
    padding: 15px 30px;
}

.haeder_headline_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  flex-wrap: wrap;
}
.headernav__main_wrap{
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
  padding: 12px 30px;
}

.header__logo {
  display: flex;
  align-items: center;
}
.header__contact_info {
  max-width: 340px;
  width: 100%;
}
.header__contact_info_ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.header__contact_info_ul li {
    display: flex;
    align-items: center;
    /* margin-left: 15px; */
}

.header__contact_info_ul li span {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.header__contact_info_ul li span em {
    width: 100%;
    font-style: inherit;
    font-size: 11px;
    color: #000;
}

.header__contact_info_ul li a {
        text-decoration: none;
    color: #131466;
    font-size: 15px;
    line-height: 1;
    display: block;
}



.header__dropdown_btn {
  position: relative;
  border: none;
  background: #fff;
  border: 1px solid #0d6efd;
  color: #0d6efd;
  font-size: 15px;
  font-weight: 700;
  padding: 4px 6px;
  border-radius: 4px;
  margin-right: 10px;
}

.header__dropdown_btn:hover {
  background: #0d6efd;
  border: 1px solid #0d6efd;
  color: #fff;
}

.header__dropdown_menu {
  position: absolute;
  width: 350px;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  justify-content: space-between;
  padding: 15px;
  right: 0;
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;

}

.header__dropdown_wrap.hasdropdown {
  position: relative;
}

.header__dropdown_item:nth-child(1), .header__dropdown_item:nth-child(2) {
  width: 47%;
  text-align: center;
  text-decoration: none;
  border: 1px solid;
  border-radius: 5px;
  padding: 10px 0;
  font-weight: 500;
  margin-bottom: 8px;
}


.header__dropdown_item:nth-child(3),.header__dropdown_item:nth-child(4) {
  width: 47%;
  text-align: center;
  text-decoration: none;
  border: 1px solid;
  border-radius: 5px;
  padding: 10px 0;
  font-weight: 500;
  color: #fff;
  background: #0d6efd;
}

.header__dropdown_item:hover {
  background: #131466;
  color: #fff;
}


.headernav__main_wrap {
  align-items: center;
}

ul.headernav__list_main {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
  column-gap: 30px;
}

.headernav__main_wrap_mobile{display: none;}

ul.headernav__list_main a {color: #5e5e5ed9;
  font-size: 15px;
  font-weight: 600;}
.headernav__list_main .active.nav-link {
  color: #0c69f3;
}






@media only screen and (max-width: 768px) {
  .header__menu_info_wrap_desktop{display: none;}
  .headernav__main_wrap_mobile{display: block;}

.headernav__list_main {
    flex-wrap: wrap;
    margin-top: 60px;
}


button.headernav__navicon_btn {
    border: none;
    padding: 0;
    width: 30px;
    height: 24px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 6px;
    background: transparent;
}

button.headernav__navicon_btn span {
    display: block;
    width: 100%;
    height: 2px;
    background: #5E5E5E;
}

.headernav__navicon_wrap {
    display: flex;
    align-items: center;
}


button.headernav__btn_cross {
  position: absolute;
  top: 0;
  right: 0;
  width: 23px;
  height: 30px;
  border: none;
  padding: 0;
  background: transparent;
}

button.headernav__btn_cross span:nth-child(1) {
  display: block;
  width: calc(100% - 2px);
  height: 2px;
  background: #131466;
  transform: rotate(45deg);
  border-radius: 5px;
}

button.headernav__btn_cross span:nth-child(2) {
  display: block;
  width: calc(100% - 2px);
  height: 2px;
  background: #131466;
  transform: rotate(-45deg);
  border-radius: 5px;
  margin-top: -2px;
}
.header__mobile_overlay {
  display: flex;
  width: 100%;
  height: 100%;
}
.navbar-brand {
    margin: 0;
}
.headernav__main_wrap .navbar-toggler.collapsed {
  margin: 0;
}
.headernav__main_wrap {
  padding-left: 15px;
  padding-right: 15px;
}
.headernav__main_wrap nav {
  padding: 0;
}

button.navbar-toggler.collapsed {
  margin: 0 !important;
}


@keyframes scroll-text {
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.header__topbar_leftcontent{
  webkit-animation: scroll-text 12s linear infinite;
  animation: scroll-text 12s linear infinite;
}
.header__topbar_left {
  overflow: hidden;
  margin-right: 11px;
}

section.header__topbar_wrap {
  padding: 11px 15px;
}
.headernav__main_wrap {
  position: fixed;
  top: 0;
  right: 1500px;
}

.headernav__main_wrap_mobile_row {
  display: flex;
  align-items: center;
  padding: 22px;
}

.header__logo {
  width: 86%;
}

.header__contact_info {
  max-width: 70px;
}

.header__contact_info_ul li {
  margin: 0;
}

.header__logo img.img-fluid {
  max-width: 211px;
  height: auto !important;
}
.headernav__main_wrap.open {
  right: 0;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  background: #00000036;
  padding: 0;
  z-index: 9;
}

.headernav__main_wrap .headernav__main_mobile_wrap {
  flex-wrap: wrap;
  display: flex;
  background: #fff;
  width: 80%;
  max-width: 310px;
  padding: 35px;
  position: absolute;
  right: -310px;
  transition: ease all 0.5s;
}

.headernav__main_wrap.open .headernav__main_mobile_wrap{
  right: -1px;
  transition: ease all 0.5s;
}
.header__dropdown_menu {
  width: 310px;
  right: -116px;
  background: #e9e9e9;
  padding: 13px 35px;
}

.headernav__main_wrap.open ul.headernav__list_main {
  flex-wrap: wrap;
  margin: 17px 0px;
}

.headernav__main_wrap.open ul.headernav__list_main li {
  width: 100%;
  padding: 6px 0;
}

section.hero__banner_wrap {
  min-height: 40vh;
}
}



@media only screen and (min-width: 769px) and (max-width: 920px){

@keyframes scroll-text {
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.header__topbar_leftcontent{
  webkit-animation: scroll-text 12s linear infinite;
  animation: scroll-text 12s linear infinite;
}
.header__topbar_left {
  overflow: hidden;
  margin-right: 11px;
}

section.header__topbar_wrap {
  padding: 11px 15px;
}

}