

.customcarrier__wrap {
    max-width: 1320px;
    margin: 0 auto;
    padding: 100px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.customcarrier__left_col {
    width: 41%;
}
.customcarrier__left_col  img{
    width: 100%;
}
.customcarrier__heading {
    font-size: 40px;
    color: #131466;
    font-weight: 600;
}

.customcarrier__bodycopy {
    font-size: 18px;
    font-weight: 400;
    margin-top: 18px;
    margin-bottom: 30px;
}

.customcarrier__list_wrap {
    padding: 0;
    list-style: none;
}

li.customcarrier__list {
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
    line-height: 1.3;
    font-size: 18px;
}

li.customcarrier__list img {
    margin-right: 14px;
    margin-top: 5px;
}

.customcarrier__right_col {
    width: 53%;
}





@media only screen and (max-width: 768px) {


.customcarrier__wrap {
    max-width: 1320px;
    margin: 0 auto;
    padding: 100px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.customcarrier__left_col {
    width: 43%;
}

.customcarrier__heading {
    font-size: 34px;
    color: #131466;
    font-weight: 600;
}

.customcarrier__bodycopy {
    font-size: 18px;
    font-weight: 500;
    margin-top: 18px;
    margin-bottom: 30px;
}

.customcarrier__list_wrap {
    padding: 0;
    list-style: none;
}

.customcarrier__list {
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
    line-height: 1.3;
    font-size: 18px;
}

.customcarrier__list img {
    margin-right: 14px;
    margin-top: 5px;
}

.customcarrier__right_col {
    width: 65%;
}
.customcarrier__wrap {
    flex-wrap: wrap;
    padding-bottom: 10px
}

.customcarrier__left_col {
    order: 2;
    width: 100%;
}

.customcarrier__right_col {
    width: 100%;
}

.customcarrier__left_col img {
    margin-top: 15px;
    max-width: 100%;
}
li.customcarrier__list {
    font-size: 15px;
}

}