.any_question_wrap {
  padding-bottom: 100px !important;
}

.queryform__main_wrap {
  background: #0d6efd;
}

.queryform__wrap {
  display: flex;
    max-width: 1300px;
    margin: 0 auto;
    align-items: center;
    flex-wrap: wrap-reverse;
    justify-content: center;
    padding: 60px 15px 130px 15px;
    column-gap: 70px;
}

.queryform__left_form {
  width: 640px;
  background: #fff;
  padding: 30px;
  box-shadow: 2.86px 2.86px 18.62px rgba(0,0,0,.19);
  border-radius: 4px;
  position: relative;
}


.queryform__right_headers h2 {
  color: #fff;
  font-size: 60px;
  font-weight: 500;
}

.queryform__right_headers p {
  color: #fff;
  font-size: 28px;
}

.queryform__left_form .input-field {
  display: flex;
  align-items: center;
}

.queryform__left_form .input-field input {
  margin-left: 14px;
}
.input-field.frominfofields {
  width: calc(50% - 13px);
}

.queryform__left_form form {
  display: flex;
  flex-wrap: wrap;
  column-gap: 25px;
}

.input-field.textarea {
  width: 100%;
  align-items: baseline;
}

.input-field.textarea img {
  position: relative;
  top: 4px;
}
.rebot.justify-content-center {
  width: 100%;
}
.rebot.justify-content-center {
  width: 100%;
}

.queryform__btn {
  position: relative;
  border: none;
  background: #0d6efd;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  padding: 12px 30px;
  border-radius: 3px !important;
  margin-top: 30px;
  width: 47%;
}

.overlay__wrap{
  display: flex;
  background: #00000085;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  justify-content: center;
  align-items: center;
}
.overlay__img{
  border-radius: 3px;;
}
.queryform__btn:hover,.queryform__btn:active {
  background: #131466;
}
@media only screen and (max-width: 768px) {
  .queryform__right_headers h2 {text-align: center;font-size: 42px;}

  .queryform__right_headers p {
      text-align: center;
      font-size: 22px;
  }
  
  .input-field.frominfofields {
      width: 100%;
  }
  
  
}




@media only screen and (max-width: 540px) {
.queryform__btn{width:100%}
.queryform__btn:last-child{margin-top:10px}
.sigup-email{max-width: 80%; } 
.sigup-email input.input {width: 80%;}
}