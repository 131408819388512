.joincarriers__main_wrap {
    max-width: 1170px;
    margin: 100px auto 0 auto;
    text-align: center;
}
.joincarriers__heading {
    font-size: 47px;
    color: #131466;
    font-weight: 600;
}
.joincarriers__logos {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 50px;
}
.joincarriers__logos li {
    width: 20%;
}
.joincarriers__btn_wrap span {
    color: #000000;
    font-size: 41px;
    font-weight: 100;
    display: inline-flex;
    margin: 0 15px;
}
.joincarriers__btn_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
}
.joincarriers__anch,.joincarriers__anch.nav-link,.joincarriers__anch.navbar-brand {
    font-size: 23px;
    font-weight: 500;
    text-decoration: none;
    color:#0d6efd;
}/* 
.joincarriers__logos li:hover img {
    transform: rotateY(360deg);
    transition: ease all 1s;
}

.joincarriers__logos li img {
    transition: ease all 1s;
}


.hero__estimation_submit_btn{
    position: relative;
}
.hero__estimation_submit_btn:before{content:'';width: 0%;height: 100%;display: block;position: absolute;left: 0;top: 0;border-top: 3px solid #131466;border-bottom: 3px solid #131466;border-radius: 3px;}
.hero__estimation_submit_btn:after{content:'';}

.hero__estimation_submit_btn:hover:before {width: 100%;transition:ease all 0.5s} */
.joincarriers__anch:hover {
    color: #131466;
}

@media only screen and (min-width: 320px) and (max-width: 920px) {
    .joincarriers__logos li {
        width: 20%;
        min-width: 170px;
    }
    .joincarriers__logos {
        justify-content: center;
    }
    .joincarriers__heading {
        font-size: 37px;
        max-width: 310px;
        margin: 0 auto;
    }
    
    .joincarriers__main_wrap {
        margin-top: 50px;
    }
    .joincarriers__btn_wrap {flex-wrap: wrap;justify-content: center;}
    .joincarriers__anch{    font-size: 20px;}
}

@media only screen  and (max-width: 485px) {
    .joincarriers__btn_wrap span{display: none;}
    .joincarriers__btn_wrap {
        max-width: 320px;
        margin: 0 auto 80px auto;
    }
}