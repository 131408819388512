.privacy__policy_content{
    padding: 40px 5%;
    margin-bottom: 100px;
}
.privacy__policy_heading{
    font-weight: 400;
    margin-top: 25px;
    margin-bottom: 25px;
}
.privacy__policy_desc{
    color: rgb(83, 83, 83);
    margin-top: 5px;
}
.privacy__policy_descdark{
    margin-top: 5px;
}
.privacy__policy_descbold{
    font-weight: 500;
    color: black;
}
.privacy__policy_desclist{
    color: rgb(83, 83, 83);
    margin-top: 5px;
}
.privacy__policy_link{
    text-decoration: none;
    color: black;
}
.privacy__policy_highlight{
    /* background-color: red; */
    color: black;
}
.terms__conds_heading{
    font-size: 22px;
    font-weight: 500;
}
@media(max-width:900px){
    .privacy__policy_heading{
     font-size: 20px;
    }
    .privacy__policy_desc{
      font-size: 14px;
    }
    .privacy__policy_descdark{
        font-size: 14px;
    }
    .terms__conds_heading{
        font-size: 20px;
        font-weight: bold;
    }
}