
.navbar-toggler:focus{box-shadow: none !important;}
.hero__banner_heading_wrap{
    background-color: #131466;
background-image:  url(images/bannerimg.webp);
background-repeat: no-repeat;
  padding: 40px 0 110px 0;
  background-position: bottom center;
}
.hero__banner_heading {
    text-align: center;
    color: #fff;
    font-size: 40px;
}

.hero__banner_heading .color-side {
    color: #131466;
    display: block;
    background: #fff;
    max-width: fit-content;
    margin: 0 auto;
    font-weight: 700;
    font-size: 44px;
    margin-top: 13px;
    padding: 2px 6px;
}

.hero__tracking_wrap {
    max-width: 1200px;
    margin: -65px auto 0 auto;
    padding: 0 20px;
}

.hero__tracking_form form {
    background: #fff;
    padding: 20px 20px 20px 20px;
    border-radius: 0 2px 0 0;
    border: 1px solid #dddddd;
    border-top: none;
}

.hero__estimation_form_wrap {
    display: flex;
    align-items: flex-start;
    padding: 0;
    column-gap: 20px;
    padding-bottom: 15px;
}
.hero__estimation_form_dimention_est 
input::-webkit-outer-spin-button,
.hero__estimation_form_dimention_est  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hero__estimation_form_dimention_est 
input[type=number] {
  -moz-appearance: textfield;
}
span.hero__estimation_form_weight_est
input::-webkit-outer-spin-button,
span.hero__estimation_form_weight_est input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
span.hero__estimation_form_weight_est
input[type=number] {
  -moz-appearance: textfield;
}


.hero__estimation_form_area_est input {
    width: 100%;
    border: 1px solid #C8C8CB;
    height: 44px;
    text-indent: 5px;
    border-radius: 2px;
}

.hero__form_track_submit_btn {
    border: none;
    background: #0d6efd;
    color: #fff;
    font-size: 15px;
    padding: 11px 0;
    width: 110px;
}

button.hero__form_tabs_btn.btn.btn-primary {
    color: #131466;
    font-size: 16px;
    font-weight: 600;
    background: #fff;
    border: none;
    align-items: center;
    display: inline-flex;
    padding:9px 20px;

}

button.hero__form_tabs_btn.btn.btn-light {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    display: inline-flex;
    padding:9px 20px ;
}

button.hero__form_tabs_btn.btn.btn-light img {
    width: 30px;
}
button.hero__form_tabs_btn.btn.btn-primary img {
    filter: invert(13%) sepia(56%) saturate(2672%) hue-rotate(180deg) brightness(36%) contrast(138%);
    width: 30px;
}

.hero__estimation_form_area_est label {
    margin: 0;
    width: 100%;
    color: #0d6efd;
    font-weight: 600;
    font-size: 14px;
}

.hero__estimation_form_area_est {
    display: flex;
    flex-wrap: wrap;
    width: 31%;
}

.hero__estimation_form_dimention_est {
    width: 44%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 4px;
    justify-content: space-between;
    }

.hero__estimation_form_dimention_est label {
    width: 100%;
    margin: 0;
    color: #0d6efd;
    font-weight: 600;
    font-size: 14px;
}

.hero__estimation_form_dimention_est input {
    width: 32%;
    border: 1px solid #C8C8CB;
    height: 44px;
    text-indent: 5px;
    border-radius: 2px;
}

.hero__estimation_form_weight_est {
    width: 26%;
    display: flex;
    flex-wrap: wrap;
}

.hero__estimation_form_weight_est label {
    margin: 0;
    color: #0d6efd;
    font-weight: 600;
    font-size: 14px;
}


.hero__estimation_form_weight_est input {
    width: 100%;
    border: 1px solid #C8C8CB;
    height: 44px;
    text-indent: 5px;
    border-radius: 2px;
}
.hero__tracking_form .ero__tracking_form_trackfld {
    width: calc(100% - 110px);
    border: 1px solid #C8C8CB;
    height: 44px;
    text-indent: 5px;
    border-radius: 2px;
}
.hero__form_track_submit_btn {
    border: none;
    background: #0d6efd;
    color: #fff;
    font-size: 15px;
    padding: 11px 0;
    width: 110px;
}
.hero__estimation_submit_btn {
    position: relative;
    border: none;
    background: #0d6efd;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    padding: 12px 30px;
    border-radius: 3px !important;
}
.tracking__response_heading {
    display: block;
    width: 100%;
    color: #131466;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #d3d3d3;
    padding: 12px 18px;
}
.hero__tracking_infobtn_wrap {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
}

.hero__form_signup_btn {
    position: relative;
    border: none;
    background: #131466;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 60px;
    border-radius: 3px !important;
    text-decoration: none;
    margin-right: 10px;
}

.hero__form_signup_btn:hover {
 color: #fff;
}

button.hero__form_refresh_btn {
    position: relative;
    border: none;
    background: #0d6efd;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 60px;
    border-radius: 3px !important;
}


.hero__tracking_response_wrap {
    border: 1px solid #dddddd;
    border-top: none;
    padding-bottom: 15px;
}
.hero__estimation_submit_wrap {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.hero__tracking_form button img {
    padding-left: 0 !important;
    width: 23px !important;
}
.hero__tracking_form form input::placeholder {
    font-size:14px;
    color:#b4b4b4
}
.hero__tracking_form form input{
    font-size:14px
}
.rdt_TableCol {
    justify-content: center;
}

.hbotQl {
    font-size: 14px;
    font-weight: 600;
    color: #131466;
}

.rdt_TableCell {
    justify-content: center;
    padding: 0;
}

.rdt_TableCol {
    padding: 0;
}

.hero__tracking_infobtn_wrap {
    margin-top: 15px;
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.css-1vooibu-MuiSvgIcon-root,.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.css-1vooibu-MuiSvgIcon-root path {
    color: #0d6efd;
    fill: #0d6efd;
}
.hero__estimation_unit_heading {
    font-size: 17px;
    font-weight: 600;
}
/*media queries start*/
@media only screen and (min-width: 320px) and (max-width: 920px) {
    span.hero__estimation_form_area_est {
        width: 100%;
        margin-bottom: 10px;
    }
    
    span.hero__estimation_form_dimention_est {
        width: 100%;
        margin-bottom: 10px;
        column-gap: 0.5%;
    }
    
    .hero__estimation_form_wrap {
        flex-wrap: wrap;
    }
    
    
    .hero__estimation_form_dimention_est input {
        width: 33%;
    }
    
    .hero__estimation_form_weight_est {
        width: 100%;
    }
    .hero__estimation_submit_wrap {
        margin-top: 20px;
    }
    .hero__form_signup_btn,button.hero__form_refresh_btn {
        width: 50%;
        padding-left: 0;
        padding-right: 0;
        display: inline-flex;
        justify-content: center;
        margin: 0;
    }
    
    .hero__tracking_infobtn_wrap {
        padding: 0 15px;
        column-gap: 10px;
    }

}

@media only screen and (max-width: 600px) {
    .hero__banner_heading .color-side {
        max-width: 80%;
        font-size: 29px;
    }
    .navbar-toggler {
        padding: 0 !important;
      }
      .navbar-toggler-icon {
        background-size: 70% !important;
      }
      button.hero__form_tabs_btn.btn.btn-primary,button.hero__form_tabs_btn.btn.btn-light{
        padding: 9px 13px;
      }
      .hero__banner_heading{font-size: 32px;}
      button.hero__form_tabs_btn.btn.btn-primary,button.hero__form_tabs_btn.btn.btn-light{    font-size: 14px;    padding: 9px 10px;}
      .zerocommission__wrap{   padding: 50px 30px;}
}
