.tracking__screen_content {
  width: 100%;
  padding: 50px 5%;
}
/* .tracking__screen_toptab {
  max-width: 100%;
  width: fit-content;
  background: #ffffff;
  border: 1px solid #f3f7f0;
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  overflow: auto;
  margin: auto;
  max-height: 600px;
} */
.tracking__screen_toptab::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.tracking__screen_toptab::-webkit-scrollbar {
  width: 6px !important;
  background-color: #f5f5f5;
}

.tracking__screen_toptab::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-linear-gradient(
    top,
    #e4f5fc 0%,
    #bfe8f9 50%,
    #9fd8ef 51%,
    #2ab0ed 100%
  );
}

.tracking__stt_table {
  padding: 20px;
}
.tracking__stt_tableheadings {
  display: flex;
}
.tracking__stt_tableheading {
  width: 140px;
  min-width: 140px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #131466;
}
.tracking__stt_tablerow {
  display: flex;
}
.tracking__stt_tabledata {
  width: 140px;
  min-width: 140px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #131466;
  flex-wrap: wrap;
}
.tracking__stt_tabledatabadge {
  background: rgba(183, 183, 185, 0.28);
  border-radius: 1px;
  padding: 2px 5px;
  font-size: 10px;
  margin-right: 5px;
}
.tracking__screen_btns {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0;
}
.trackings__inquire_btn {
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  border: none;
}
.abtscreen__banner_loading {
  width: 100%;
  margin: 100px 0 150px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tracking__screen_formfield {
  width: 80%;
  height: 45px;
  border: 1px solid lightgrey;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 0 2%;
  max-width: 600px;
}

.tracking__screen_formbtn {
  width: 20%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0c69f3;
  border-color: #0c69f3;
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  max-width: 120px;
  min-width: 60px;
}

/*Modified Css*/


.packagedetails__main_wrap {
  width: 100%;
  max-width: 1024px;
  margin: 30px auto 150px auto;
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.25);
  padding: 28px;
  border-radius: 6px;
  border: 1px solid #e7e4e4;
}

.packagedetails__parcelDetails {
    font-size: 14px;
    margin-top: -20px;
}

.packagedetails__list_wrap {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  border: 1px solid #d7d7d7;
  padding: 5px 0;
  margin-bottom: 10px;
  border-radius: 3px;
  align-items: center;
}

.packagedetails__list_header_row {
  display: flex;
  text-align: center;
  border: 1px solid #d7d7d7;
  padding: 10px 0;
  margin-bottom: 10px;
  border-radius: 3px;
  background: #131466;
  color: #fff;
}
.packagedetails__list_header_index {
  width: 5%;
}
.packagedetails__list_header_lastupdate {
  width: 25%;
}
.packagedetails__list_header_laststatus {
  width: 15%;
}
.packagedetails__list_header_description {
  width: 20%;
}
.packagedetails__list_header_barcode {
  width: 15%;
}
.packagedetails__list_header_size {
  width: 10%;
}
.packagedetails__list_header_weight {
  width: 10%;
}
.packagedetails__list_index {
  width: 5%;
}
.packagedetails__list_lastupdate {
  width: 25%;
}
.packagedetails__list_laststatus {
  width: 15%;
}
.packagedetails__list_description {
  width: 20%;
}
.packagedetails__list_barcode {
  width: 15%;
}
.packagedetails__list_size {
  width: 10%;
}
.packagedetails__list_weight {
  width: 10%;
}


.packagedetails__overallDetails {
    display: flex;
    color: #131466;
    border-radius: 3px;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.packagedetails__overallDetails span {
    width: 100%;
    display: block;
}

.packagedetails__list_pickup {
    width: fit-content;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}

.packagedetails__list_mastertracking {
    width: fit-content;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}

.packagedetails__list_carriertracking {
    width: fit-content;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}

.packagedetails__list_shipmentbooked {
    width: fit-content;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}

.packagedetails__list_packagecount {
    width: fit-content;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}

.packagedetails__list_overallweight {
    width: fit-content;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}

.packagedetails__list_carrierlogo {
  width: fit-content;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}

.packagedetails__list_carrierlogo img {
  width: 100%;
    background: #e0edff;
    padding: 5px;
    border-radius: 5px;
    max-width: 40px;
}

.packagedetails__main_header_content {
  width: 100%;
  max-width: 1024px;
  margin: 60px auto 0px auto;
  display: flex;
}

.packagedetails__content_wrap {
  width: 100%;
  text-align: center;
}

.packagedetails__button_wrap {
  width: 35%;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 3;
}

.packagedetails__content_wrap h2 {
  font-size: 24px;
  color: #131466;
}

.packagedetails__content_wrap h4 {
  font-size: 20px;
  color: #131466;
  font-weight: 500;
}

.packagedetails__content_wrap h2 span {
  color: #0C69F3;
  font-weight: 600;
}
.packagedetails__button_reviewbtn {
  position: relative;
  border: none;
  background: #0d6efd;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  padding: 9px 13px;
  border-radius: 4px;
  margin-right: 5px;
}

.packagedetails__button_inquirebtn {
  position: relative;
  border: none;
  background: #0d6efd;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  padding: 9px 3px 9px 13px;
  border-radius: 4px;
}

.packagedetails__button_list {
  position: absolute;
  top: 79%;
  right: 0;
  list-style: none;
  width: 100%;
  max-width: 150px;
  background: #fff;
  padding: 9px 11px;
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 3px 3px;
  border: 1px solid #e7e4e4;
}

.packagedetails__button_list li {
  cursor: pointer;
  font-size: 13px;
  border-bottom: 1px solid #e7e4e4;
  padding: 6px 0;
}

.packagedetails__button_list li:last-child{
  border: none;
  padding-bottom: 0;
}

.packagedetails__button_list li:hover {
  color: #0d6efd;
}
.packagedetails__inquireform_main_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.packagedetails__inquireform_overlay {
  width: 100%;
  height: 100%;
  background: #03132a3d;
  position: absolute;
  z-index: 1;
}

.packagedetails__inquireform_wrap {
  background: #fff;
  position: relative;
  width: 100%;
  max-width: fit-content;
  text-align: center;
  z-index: 2;
  padding: 20px 10px 30px 10px;
}

.packagedetails__overallDetails_master {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}

.packagedetails__overallDetails_master span {
    display: block;
    font-weight: 600;
    width: 100%;
    text-align: center;
}


.packagedetails__overallDetails_overall {
    font-size: 14px;
    text-align: center;
    border-top: 1px solid #d7d7d7;
    margin-top: 15px;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
}

.packagedetails__overallDetails_overall span {
    display: block;
    font-weight: 600;
    width: 100%;
    text-align: center;
}


.packagedetails__parcelDetails_heading {
  font-size: 22px;
  color: #0d6efd;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 20px;
}
.packagedetails__content_wrap h2 span.cancelled-class {
  color: red;  
  text-decoration: line-through;
}

.packagedetails__list_laststatus.cancelled-class {
  color: red;
  text-decoration: line-through;
}

.packagedetails__overallDetails_overall>div {
  min-width: 190px;
}

.packagedetails__overallDetails_master>div {
  min-width: 130px;
}

.packagedetails__inquireform_form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 520px;
  justify-content: space-between;
  padding: 0 20px;
}

.packagedetaails__ticketform_field input {
  border: none;
  height: 50px;
  width: 100%;
  text-indent: 12px;
}

.packagedetaails__ticketform_field {
  width: 48%;
  display: flex;
  align-items: center;
  border: 1px solid #DEDEDE;
  padding-left: 14px;
  margin-bottom: 20px;
}


.packagedetails__inquireform_form button[type=submit] {
  width: 100%;
  border: none;
  background: #0C69F3;
  color: #fff;
  height: 60px;
  border-radius: 4px;
}
.packagedetails__inquireform_form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 520px;
  justify-content: space-between;
  padding: 0 20px;
}

.packagedetaails__ticketform_field input {
  border: none;
  height: 50px;
  width: 100%;
  text-indent: 12px;
}

.packagedetaails__ticketform_field {
  width: 48%;
  display: flex;
  align-items: center;
  border: 1px solid #DEDEDE;
  padding-left: 14px;
  margin-bottom: 20px;
}


.packagedetails__inquireform_form button[type=submit] {
  width: 100%;
  border: none;
  background: #0C69F3;
  color: #fff;
  height: 60px;
  border-radius: 4px;
}



.packagedetails__inquireform_header svg {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.packagedetails__inquireform_header h3 {
  font-size: 22px;
  color: #131466;
  margin-bottom: 15px;
}


.packagedetails__inquireform_header svg {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.packagedetails__inquireform_form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 520px;
  justify-content: space-between;
  padding: 0 20px;
}

.packagedetaails__ticketform_field input {
  border: none;
  height: 50px;
  width: 100%;
  text-indent: 12px;
}

.packagedetaails__ticketform_field {
  width: 48%;
  display: flex;
  align-items: center;
  border: 1px solid #DEDEDE;
  padding-left: 14px;
  margin-bottom: 20px;
  border-radius: 2px;
}


.packagedetails__inquireform_form button[type=submit] {
  width: 100%;
  border: none;
  background: #0C69F3;
  color: #fff;
  height: 60px;
  border-radius: 4px;
}



.packagedetails__inquireform_header svg {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.packagedetails__inquireform_header h3 {
  font-size: 22px;
  color: #131466;
  margin-bottom: 15px;
}

.packagedetails__inquireform_ticket_purpose {
  display: block;
  font-size: 13px;
  color: #e70000;
  border: 1px solid;
  width: fit-content;
  margin: 0 auto;
  padding: 2px 4px 3px 4px;
  margin-top: 5px;
  border-radius: 2px;
}
.packagedetails__inquireform_wrap:hover:after {
  content: 'Coming Soon';
  width: 100%;
  height: 100%;
  background: #000000c4;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 41px;
}

.trackingstatus__main_heading {
  text-align: center;
}
.cancelled-class.packagedetails__main_wrap:after {
  content: 'Cancelled';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: #000000b5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  color: red;
  text-decoration: line-through;
}

.cancelled-class.packagedetails__main_wrap {
  position: relative;
}
.packagedetails__content_wrap h2 span.delivered-class,.packagedetails__content_wrap h4 span.delivered-class {
  color: #059f05;
}
/* parcel history */


.trackingstatus__main_col {
  position: relative;
  display: flex;
  width: fit-content;
  padding-top: 5px;
}

.trackingstatus__main_col:before {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background: #0d6efd;
  position: absolute;
  top: 23px;
}

.trackingstatus__main_wrap {
  max-width: 1024px;
  margin: 30px auto 0 auto;
  overflow: auto;
  cursor: grab;
}

.trackingstatus__list {
  min-width: 170px;
  font-size: 12px;
  padding: 0 20px;
  text-align: center;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}

.trackingstatus__list:before {
  content: '';
  width: 20px;
  height: 20px;
  display: block;
  background: #0d6efd;
  border-radius: 100%;
  margin: -10px auto 0 auto;
  z-index: 2;
  outline: 2px solid #0d6efd;
  border: 5px solid #fff; 
}

.trackingstatus__list_item.statusdate {
  margin-top: 5px;
  font-weight: 600;
  color: #0d6efd;
}

.trackingstatus__list_item.shipmentstatus {
  color: #131466;
  font-size: 13px;
  line-height: 1.4;
  margin: 30px 0 12px 0 ;
}

.trackingstatus__list.creation .trackingstatus__list_item.shipmentstatus {
  margin: 12px 0;
}

.trackingstatus__list.delivered  .trackingstatus__list_item.shipmentstatus {
  margin: 12px 0;
}
.trackingstatus__list.cancelled::before {
  background: red;
}

.trackingstatus__list.cancelled .trackingstatus__list_item.shipmentstatus {
  color: red;
  text-decoration: line-through;
}

.trackingstatus__list.cancelled .trackingstatus__list_item.statusdate {
  color: red;
  text-decoration: line-through;
}
.trackingstatus__list.delivered:before {
  content:'';  
  color: #fff;
  line-height: 1.6;
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin-top: -40px;
  background: #059f05;
  outline-color: #059f05;
}
.trackingstatus__list.delivered .trackingstatus__list_item.statusdate {
  color: #059f05;
}
.trackingstatus__list.creation:before {
  content:'';
  color: #fff;
  line-height: 1.6;
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin-top: -40px;
}
.trackingstatus__main_wrap::-webkit-scrollbar {
  width: 13px;
   height: 6px;
}
 
.trackingstatus__main_wrap::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
     border-radius:5px
}
 
.trackingstatus__main_wrap::-webkit-scrollbar-thumb {
  background-color: #babcbf;
    border-radius:5px
}
.packagedetails__content_wrap h4 .cancelled-class {
  color: red;
  text-decoration: line-through;
}
.trackingstatus__list.creation:after {
  content: "";
  width: 58px;
  height: 3px;
  display: block;
  position: absolute;
  right: 0;
  top: 18px;
  background: #0d6efd;
}
.trackingstatus__main_wrap.dragging {
  cursor: grabbing !important;
}
.trackingstatus__list.delivered:after {
  content: "";
  width: 58px;
  height: 3px;
  display: block;
  position: absolute;
  left: 0;
  top: 18px;
  background: #0d6efd;
}
/* default page carrier listing  */

.tracking__screen_form_wrap {
  box-shadow: 6px 3px 33px 0px #0000003d;
  max-width: 1024px;
  margin: 0 auto;
  border-radius: 5px;
  padding: 40px;
  margin-bottom: 100px;
}

.tracking__screen_form_heading {
  text-align: center;
  font-size: 40px;
  color: #131466;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 25px;
}

form.tracking__screen_form {
  display: flex;
  justify-content: center;
}

.tracking__screen_carriers_list {
  display: flex;
  padding: 0;
  list-style: none;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 60px;
  align-items: center;
  margin-top: 30px;
}

ul.tracking__screen_carriers_list img {
  width: 100%;
}

.tracking__screen_carriers_list li {
  max-width: 140px;
}
.tracking__screen_carrier_listing_heading {
  text-align: center;
  font-size: 40px;
  color: #131466;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 25px;
}
.trackingstatus__list.creation,.trackingstatus__list.delivered {
  padding-top: 10px;
}



@media (max-width: 480px) {
  .tracking__screen_form {
    font-size: 14px;
    height: 120px;
  }
  .tracking__screen_formfield {
    height: 38px;
  }
  .tracking__screen_formbtn {
    height: 38px;
  }

  .tracking__screen_carrier_listing_heading {font-size: 26px;}
  .tracking__screen_form {height: auto;}
  .tracking__screen_carriers_list li {max-width: 85px;}
  .tracking__screen_form_wrap {padding: 10px 23px;}
  .tracking__screen_carriers_list {column-gap: 20px;}
}




@media (min-width: 790px) {

.trackingstatus__list.creation.no-dashes {
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 2;
}

.trackingstatus__list.delivered {
  position: sticky;
  top: 0;
  right: 0;
  background: #fff;
  z-index: 2;
}
.trackingstatus__list.cancelled {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}

.trackingstatus__list.cancelled:after {
  content: '';
  width: 73px;
  height: 3px;
  display: block;
  position: absolute;
  right: 0;
  top: 18px;
  background: #ffffff;
}
}

@media (max-width: 1023px) {

.packagedetails__main_header_content {
  flex-wrap: wrap;
  justify-content: center;
}

.packagedetails__button_wrap {
  width: 100%;
  justify-content: center;
  max-width: 255px;
}

.packagedetails__content_wrap h2 {
  font-size: 20px;
}

.packagedetails__content_wrap h4 {
  font-size: 16px;
}
.packagedetails__list_wrapper {
  width: 100%;
  overflow: auto;
}

.packagedetails__list_header_wrap {
  width: 800px;
}

.packagedetails__list_wrap {
  width: 800px;
}

.packagedetails__overallDetails_main_wrapper {
  width: 100%;
  overflow: auto;
  padding-bottom: 10px;
}

.packagedetails__overallDetails_master {
  width: 800px;
}

.packagedetails__overallDetails_overall {
  width: 950px;
}
.trackingstatus__main_wrap,.packagedetails__main_wrap {
  width: 85%;
}
.packagedetails__inquireform_wrap {

  width: 90%;
}
}