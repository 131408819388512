* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
.bta {
  width: 48%;
  border-radius: 0 !important;
  margin-left: 6px;
}
hr {
  margin: 0 !important;
}
.container {
  width: 1140px !important;
  max-width: 100% !important;
}
.header {
  height: 40px;
  background: #0c69f3;
}
.header-text {
  color: #fff;
  font-weight: 400;
  padding: 11px 0;
  font-size: 15px;
  line-height: 22px;
}
.header-text .btn {
  padding: 0px 14px;
  margin: -4px -187px 15px 12px;
}

.btn-primary .btn1:hover {
  content: url("../images/shop (3) 1.png") !important;
}
.header .cross {
  color: #fff;
  padding: 0px;
  margin-top: 11px;
}
.header-button {
  margin-top: -15px;
  margin-bottom: -6px;
}
.menus {
  padding-top: 14px;
}
.contact {
  display: flex;
  justify-content: end;
}
.contact span {
  color: #131466;
  margin: 0 0px 0 37px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.contact a:hover {
  color: #0a58ca !important;
}
.one {
  width: 3.333333% !important;
  margin-left: 42.7% !important;
}
.two {
  width: 6.333333% !important;
}
.three {
  width: 6.333333% !important;
  margin-left: -36px !important;
}
.email {
  padding: 0 37px;
  font-size: 15px;
}
.color-side {
  color: #0c69f3;
}
/* .navbar-nav {
  margin: 0 -9px;
} */
.nav-item {
  color: #131466;
  font-weight: 400;
  font-size: 15px;
  padding: 12px;
  padding-bottom: 0;
  padding-left: 0;
}
a.nav-link {
  color: #131466;
}
.imp_met label {
  margin: unset !important;
}
.butons {
  display: flex;
  justify-content: end;
}
.butons a {
  padding: 6px 7px;
  margin: 15px 8px 15px 9px;
  text-decoration: none;
  border-radius: 2px;
}
.butons .link {
  /* text-decoration: none; */
  border: 1px solid rgba(19, 20, 102, 1);
  /* padding: 5px 9px;
  margin: 16px; */
  color: rgba(19, 20, 102, 1);
}

.butons i {
  padding: 0 0 0 11px;
  font-size: 22px;
  color: #131466;
  margin: 16px 5px;
}
.trackbtn {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  margin-top: 3px !important;
}
.trackfld {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.menus .container {
  margin-top: -9px;
  margin-bottom: -17px;
}
.ship-input input {
  font-size: 11.62px;
  width: 181px;
  height: 39px;
  padding-left: 17px;
  margin-top: 16px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.19);
  box-shadow: 1px 1px 8px rgb(0 0 0 / 11%);
  border-radius: 3px;
}
.radio .ship-input {
  font-size: 11.62px;
  width: 181px;
  height: 39px;
  padding-left: 17px;
  margin-top: 16px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.19);
  box-shadow: 1px 1px 8px rgb(0 0 0 / 11%);
  border-radius: 3px;
}
input.radio1 {
  margin: 0px -6px 0 18px;
}
label {
  margin: 21px 11px;
}

.top-content {
  font-weight: 600;
  line-height: 56px;
  padding: 15px 0 0 0;
  margin-top: -11px;
}
.top-content p {
  color: #000;
  font-size: 40px;
  font-weight: 700;
  padding: 21px 0 0 0;
}

.num {
  border-right: 2px solid #0c69f3;
  color: #131466;
  font-size: 44.9245px;
}

.color-side1 {
  padding: 0 10px;
  color: #0c69f3;
  font-size: 32.92px;
}
.autotext {
  margin-top: -67px !important;
}

.top-content .btn {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  border: none;
  font-size: 13.99px;
  line-height: 18px;
  color: #000000;
  padding: 12px 22px;
  margin: 13px;
}
.top-content img.truck-img {
  margin-bottom: 21px;
  margin-left: 39%;
  width: 13%;
}
.form ,.form2{max-width: 1220px;margin: 0 auto;}

.form {
  border-radius: 3px;
  padding: 15px 12px;
}

section.hero-section.container-fluid .form2.shadow {
  border-radius: 3px;
  padding: 15px 12px;
  z-index: 5;
}
.form.form-box-wrap {
  margin-top: -86px;
  min-height: 220px;
}

.form.form-box-wrap input.trackfld {
  width: 92%;
}

.form .location .btn {
  border: none;
  color: #131466;
  padding: 9px 12px;
  background: rgba(12, 105, 243, 0.29);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.11);
  border-radius: 3px;
  font-size: 13px;
}
.form .shipping .btn {
  padding: 9px 13px;
  font-size: 13px;
}
.form2 .shipping .btn {
  border: none;
  color: #131466;
  padding: 9px 12px;
  background: rgba(12, 105, 243, 0.29);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.11);
  border-radius: 3px;
  font-size: 13px;
}
.radio {
  margin-top: -9px;
}
.form input {
  font-size: 11.62px;
  width: 277px;
  height: 39px;
  padding-left: 17px;
  margin-top: 16px;
  margin-bottom: 16px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.19);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.11);
  border-radius: 3px;
}
.form span {
  font-size: 13.99px;
}
.shipping i {
  font-size: 16px;
  color: #131466;
}
.form .btn {
  font-size: 11.75px;
  padding: 10px 26px;
}
.form2 .btn {
  font-size: 11.75px;
  padding: 10px 26px;
}
.form .track-btn {
  background: #0c69f3;
  border: none;
  color: #fff;
  font-size: 11.75px;
}
.form-block input {
  font-size: 11.62px;
  width: 50%;
  height: 39px;
  padding-left: 17px;
  margin-bottom: 16px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.19);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.11);
  border-radius: 3px;
  margin: 13px 14px 10px 0;
}
input.form-block {
  font-size: 11.62px;
  width: 194px;
  height: 39px;
  padding-left: 17px;
  margin-bottom: 16px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.19);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.11);
  border-radius: 3px;
  margin: 13px 14px 10px 0;
}

.logos img {
  width: 100px;
  margin: 18px;
}
.carrier h3 {
  font-weight: 600;
  font-size: 49px;
}
.carrier h1 {
  font-weight: 600;
  line-height: 1;
  margin-top: 0;
  padding-bottom: 6px;
  font-size: 40.5769px;
}
.list img {
  width: 22px;
  margin-left: -28px;
}
.service-content {
  font-weight: 600;
  font-size: 18.8px;
  line-height: 28px;
  color: #6a6a6a;
}
.list li {
  font-weight: 500;
  list-style: none;
  padding: 10px 0;
  font-size: 18.8px;
  color: #6a6a6a;
}
.e-commerce {
  background: #f3f3f3;
  overflow: hidden;
}
.e-commerce h2 {
  font-weight: 700;
}
.e-commerce p {
  font-size: 18.58px;
  padding-top: 11px;
}
.e-commercetext {
  text-align: center;
  margin-top: 22px;
}
.img-container {
  display: inline-block;
  position: relative;
  margin-top: -50px;
}

.img-container a.btn {
  position: absolute;
  top: 72%;
  left: 69%;
}

#item3 .rect {
  margin-bottom: -189px;
}
.buttons {
  padding: 0 20px;
}
.buttons .btnn {
  border: 1px solid #ccc;
  color: #a6a6a6;
  border: 1px solid #dedede;
}
.buttons .btnn:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}
.buttons .set1 {
  margin: 0 15px;
  font-size: 15px;
  padding: 11px;
}
.butons .set1 {
  /* margin: 9px 6px; */
  font-size: 17px;
  background-color: #fff;
  border: 1px solid;
  border-color: #0c69f3;
  color: #0c69f3;
  /* border-radius: 5px; */
  /* padding: 11px; */
}
.butons .set2 {
  /* margin: 9px 6px; */
  font-size: 17px;
  /* padding: 11px; */
}
.plugin-work {
  background-color: #0c69f3;
  color: #fff;
}
.plugin-work h2 {
  text-align: center;
}
.iconss {
  background-color: #fff;
  color: #131466;
  border-radius: 50%;
  padding: 34px;
  margin: 33px;
}

.arrow img {
  position: relative;
  left: 73%;
  top: 25px;
}
.arrow-down img {
  position: relative;
  top: -132px;
  left: 128px;
}
.arrow-down2 {
  position: relative;
  top: -189px;
  left: 134px;
}
.iconss1 {
  background-color: #131466;
  color: #fff;
  border-radius: 50%;
  padding: 34px;
  margin: 33px;
}

.icon-content h5 {
  font-weight: 500;
  font-size: 17.0086px;
}
.icon-content p {
  font-weight: 400;
  font-size: 12.7565px;
  line-height: 19px;
}

.shipping h2 {
  font-weight: 700;
  font-size: 35.5816px;
  color: #454545;
  line-height: 0;
}
.shipping-heading h3 {
  font-size: 35.5816px;
  line-height: 53px;
  color: #454545;
}
.shipping-heading img {
  height: fit-content;
  margin: 11px 14px;
  margin-left: 0;
}
.image img {
  padding: 98px 0;
}
.box1 {
  height: 173px;
  margin: 34px 0;
  padding: 30px 0;
}

.box1 h2 {
  padding-bottom: 10px;
  font-weight: 600;
  padding-left: 14px;
  font-size: 25px;
}
.box1 p {
  font-size: 19.8px;
  padding: 0 44px;
  line-height: 29px;
}
.boxes i {
  color: #131466;
  font-size: 24px;
}

.blue-container {
  margin-top: -100px;
  position: relative;
  top: 125px;
}
.blue-container .container {
  background-image: url("../images/blue-background.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 100%;
  color: #fff;
  background-color: #131466;
  padding: 67px 0;
}
.blue-container p {
  line-height: 1;
  font-size: 31px;
  margin: 0;
}
.blue-container h2 {
  font-size: 33px;
  font-weight: 700;
}
.blue-container .buttonss a {
  margin: 10px 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px 30px;
  border-radius: none !important;
}
.real-time {
  background-color: #f3f3f3;
  padding-top: 200px;
}

.real-time h2 {
  font-size: 35px;
  font-weight: 700;
  line-height: 53px;
}
.real-time p {
  font-size: 19.8px;
  line-height: 30px;
  padding: 0 6px;
}
.blog.py-5 {
  overflow: hidden;
}
.blog {
  background-color: #163c75;
}
.blog h1 {
  color: #fff;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
}
.card-body {
  margin: 20px;
}
small.text-muted {
  font-weight: 400;
  font-size: 10.59px;
  line-height: 16px;
  color: #616161;
}
.card-title {
  font-weight: 500;
  font-size: 17.59px;
  line-height: 19px;
  color: #000000;
}
.card-text {
  font-weight: 400;
  font-size: 10.59px;
  line-height: 16px;
  color: #000000;
}
.card-body button {
  font-weight: 500;
  font-size: 10.554px;
  line-height: 12px;
}
.testimoinal h1 {
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  padding: 56px 0;
}
.testimoinal p {
  font-size: 19.8px;
  line-height: 30px;
  text-align: center;
  color: #454545;
}
.any-question {
  background-color: #0c69f3;
}
.any-form {
  box-shadow: 2.86px 2.86px 18.62px rgba(0, 0, 0, 0.19);
  border-radius: 3.72px;
  background-color: #fff;
  padding: 30px;
}
.input-field {
  border: 0.862069px solid #dedede;
  border-radius: 3.44828px;
  margin-bottom: 15px;
  padding: 16px;
  color: #131466;
}
.input-field input {
  border: none;
  width: 100%;
}
.input-field input::placeholder {
  color: #131466;
}
.input-field textarea::placeholder {
  color: #131466;
}
.input-field textarea {
  border: none;
  width: 100%;
  padding: 0 10px;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
  color: #131466;
}
.any-form .btn {
  width: 100%;
  box-shadow: 1.72414px 1.72414px 6.89655px rgba(0, 0, 0, 0.25);
  border-radius: 2.58621px;
}
.asking {
  color: #fff;
}
.asking h1 {
  font-weight: 600;
  font-size: 49.4118px;
}
.asking p {
  font-weight: 600;
  font-size: 26.1532px;
}
input.input {
  position: relative;
  border: 1px solid #0c69f3;
  background-color: #131466;
  color: #fff;
  padding: 5px 44px;
  margin: 0px -7px 0 0;
}
.sigup {
  padding: 49px 0;
}
.sigup img {
  position: relative;
  color: #fff;
  position: absolute;
  z-index: 1;
  top: 62px;
}
.signup-box {
  background-color: #eff6ff;
}

.signup-banner input::placeholder {
  color: #fff;
}
.signup-banner input:focus {
  background-color: #131466;
  color: #fff;
}
.input-group {
  width: 67% !important;
}
.signup-banner h2 {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 0;
}

.footer {
  background-color: #eff6ff;
}
.footer-base {
  border-top: 1px solid #484848;
  color: #131466;
  font-weight: 500;
}
.footer-base p {
  font-weight: 500;
  font-size: 15px;
}
.footer-base .icons {
  text-align: end;
}
.logo img {
  width: 240px;
  max-width: 100%;
  margin-left: 29px;
}
.footer h5 {
  color: #131466;
  font-weight: 700;
  font-size: 18.26px;
  margin-left: -6px;
}
.policy a {
  text-decoration: none;
  color: #131466;
  padding: 0 18px;
  font-weight: 500;
  font-size: 15px;
}
.icons .ins {
  padding: 0 7px;
  font-size: 40px;
  color: #131466;
  margin-left: 11px;
}
.icons .ins:hover {
  color: #0a58ca;
}
.get-in-touch a {
  text-decoration: none;
  color: #131466;
  font-size: 14px;
}
.logo p {
  font-size: 14px;
  padding-left: 27px;
}

.footer ul li a {
  text-decoration: none;
  color: #484848;
}
.footer ul li {
  font-weight: 400;
  font-size: 14px;
  list-style: none;
  padding: 5px 0;
  margin: 0 -6px;
}
.footer ul li a:hover {
  text-decoration: none;
  color: #0a58ca;
}
.footer-base i {
  padding: 0 10px;
  font-size: 22px;
}
.typewriter {
  font-size: 30.9245px;
  font-family: sans-serif;
  color: #131466;
}
.typed-cursor {
  opacity: 1;
  font-size: 40px;
  font-weight: 100;
  color: #131466;
}
.top-content svg {
  font-size: 21.99px;
  line-height: 17px;
  /* padding: 4px 4px; */
  margin-right: 8px;
}
.dim-block input {
  font-size: 9.62px;
  width: 25%;
  height: 39px;
  padding-left: 12px;
  margin-bottom: 16px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.19);
  box-shadow: 1px 1px 8px rgb(0 0 0 / 11%);
  border-radius: 3px;
  margin: 3px 14px 10px 0;
}

.newsletter__main_wrap {
  max-width: 1180px;
  margin: -130px auto 80px auto;
  padding: 0 15px;
}

.signup-banner {
  display: flex;
  justify-content: center;
  min-height: 150px;
  align-items: center;
  background-color: #131466;
  color: #fff;
  border-radius: 3px;
}

.sigup-email {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 388px;
}

.sigup-email img {
  position: absolute;
  left: 0;
  z-index: 1;
}

.sigup-email input.input {padding-left: 30px;}

.signup-banner form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

/******* slider style *******/

.testimoinal {
  width: calc(100% - 100px);
  margin: 0 auto;
  text-align: center;
}
.slide img {
  width: 20rem;
  margin: 0 auto;
}

.slide {
  transform: scale(0.6);
  transition: transform 1s, opacity 1s;
  opacity: 0.5;
}

.activeSlide {
  transform: scale(1.1);
  opacity: 1;
  position: sticky;
  z-index: 1;
}
.testimoinal .slide {
  transform: scale(0.6);
  transition: transform 1s, opacity 1s;
  opacity: 0.5;
}

.testimoinal .activeSlide {
  transform: scale(1.2);
  opacity: 1;
  position: sticky;
  z-index: 1;
}
.testimoinal .slick-track {
  top: -13px;
  left: -41px;
}

.arrow1 {
  /* background-color: #fff; */
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

.testimoinal .slick-list {
  padding-top: 8% !important;
  padding-bottom: 2% !important;
  padding-right: 0% !important;
}
.styla img {
  width: 30rem !important;
  margin: 0 auto;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

.card {
  max-width: 545px !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  justify-content: center;
}
.cards .slick-slide img {
  display: block;
  max-width: 260px;
  margin-left: -124px;
}
/* .flex-grow-1 {
    flex-grow: 0 !important;
} */

/******* slider style *******/

@media screen and (min-width:1000px){
  .button-top{
    display: flex;
    padding-left: 0px;
    margin-left: 0px;
  }
  .button-top .btn1,.button-top .btn2{
    width: 250px ;
    height: 60px ;
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-left: 0;
  }
  .hero__btns_icon{
    width: 50px !important;
    font-size: 30px !important;
  }
}


@media screen and (max-width: 980px) {
  .butons .btn {
    display: none;
  }
  .contact {
    display: flex;
  }

  .menu-section {
    flex-direction: row;
    font-size: 14px;
  }
  .header-button {
    display: flex;
    /* justify-content: flex-end; */
  }
  .nav-item {
    text-align: end;
  }
  nav.navbar.navbar-expand-lg.p-0 {
    display: flex;
    justify-content: end;
  }
  .butons {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 0 -23px;
  }
  div#offcanvasNavbar-expand-\$\"md\" {
    background: #090a33f7;
}

.offcanvas-body a.header__nav_item.active.nav-link,.offcanvas-body a.header__nav_item {
    color: #fff;
    border-bottom: 1px solid #99999959;
}

.offcanvas-header button.btn-close  {
    color: #fff;
    padding: 0;
    opacity: 1;
}


.offcanvas-header button.btn-close:after {
    content: "\00d7";
    color: #fff;
    font-size: 26px;
    line-height: 1;
}

.offcanvas-header {
    padding: 0;
    height: 28px;
    padding-left: 15px;
    margin-top: 7px;
    margin-bottom: -10px;
}
}
.show-min-822 {
  display: none;
}
.landing__custom_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 350px;
}
@media (min-width: 600px) and (max-width: 880px) {
  .top-content p {
    font-size: 20px !important;
    position: relative;
    bottom: 48px;
    height: 77px;
  }
  .top-content .typewriter,
  .top-content .typewriter2 {
    font-size: 20px !important;
  }
}
@media (min-width: 880px) {
  .show-min-822 {
    display: block;
  }
}

@media (max-width: 880px) {
  .contact span {
    margin: 5px 0 8px 0;
  }
  .styla img {
    width: 18rem !important;
  }
  .hero-section {
    height: auto;
  }

  /* .hero-section {
    background-image: url(../images/Backgroundtruck.png), url(../images/map.png), url(../images/blue.png);
    height: 39vh;
    background-position: right -26px, left 7px;
    background-repeat: no-repeat;
    background-size: 75%, 61%;
  } */

  .plugin-work {
    overflow: hidden;
  }
  .carrier h3 {
    font-size: 34px;
  }
  .carrier h1 {
    font-size: 31px;
  }
  .list li {
    padding: 0;
  }
  .logos {
    margin: 49px 0;
  }

  .top-content p {
    font-size: 34.4126px;
    height: 77px;
  }
  .top-content .typewriter,
  .top-content .typewriter2 {
    font-size: 38px;
  }
  .top-content img.truck-img {
    margin-top: -37px;
  }
  .real-time h2 {
    font-size: 31px;
    line-height: 39px;
  }
  .real-time p {
    font-size: 16.8px;
  }
  .logos img {
    width: 76px;
    margin: 14px 8px;
  }
  .iconss {
    background-color: #fff;
    color: #131466;
    padding: 28px;
    width: 100px;
    height: 100px;
    margin: 33px 0;
  }
  .iconss1 {
    background-color: #131466;
    color: #fff;
    padding: 28px;
    width: 100px;
    height: 100px;
    margin: 33px 0;
  }
  .arrow-down img {
    position: relative;
    top: -189px;
    left: 89px;
  }
  .arrow-down2 img {
    position: relative;
    top: -71px;
    left: -39px;
  }
  .cards .slick-slide img {
    max-width: 199px;
    margin-top: 18px;
  }
  .box1 h2 {
    font-size: 20px;
  }
  .any-form .btn {
    padding: 9px;
    font-size: 13px;
  }
  .footer ul li {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .top-content p {
    font-size: 50px;
  }
  .arrow img {
    left: 46%;
    top: 247px;
    transform: rotate(90deg);
    width: 69%;
  }
  .arrow-down img {
    left: 4%;
    top: -33px;
    transform: rotate(90deg);
    width: 34%;
  }
  .arrow-down2 img {
    left: -30%;
    top: 119px;
    transform: rotate(90deg);
    width: 34%;
  }
  .cards .slick-slide img {
    margin-left: 0;
  }

  .iconss {
    background-color: #fff;
    color: #131466;
    padding: 28px;
    margin: 33px 35%;
    width: 100px;
    height: 100px;
  }
  .iconss1 {
    background-color: #131466;
    color: #fff;
    padding: 28px;
    margin: 33px 35%;
    width: 100px;
    height: 100px;
  }

  .logos {
    margin-top: -30%;
    margin: 0 -17px;
  }
  .logo p {
    font-size: 15px;
  }
  .footer h5 {
    font-size: 22px;
  }
  .footer ul li {
    font-size: 17px;
  }
  .image img {
    padding: 49px 14%;
  }
  .ship {
    text-align: center;
  }
  .box1 h2 {
    font-size: 22px;
  }
  .icon-content.text-center {
    padding: 0 20%;
  }
  .carrier-content h3,
  h1 {
    text-align: center;
  }
  .ques-any {
    flex-direction: column-reverse;
  }
  .asking {
    text-align: center;
  }
  .footer .menu {
    padding: 0 40px;
  }
  .footer-base p {
    text-align: center;
  }

  .sigup {
    flex-direction: column;
    padding: 33px 0;
  }
  
}
@media (max-width: 480px) {
  .landing__custom_loader {
    min-width: 100%;
  }
  .top-content {
    margin-top: -6px;
  }
  .br {
    display: none;
  }
  .topq {
    margin-top: 5.5rem !important;
  }
  .contact {
    display: flex;
  }
  .contact img {
    margin-left: -10px;
    max-width: 31px;
    margin-right: -8px;
  }
  a.navbar-brand {
    margin-left: 23px;
  }
  .menu-section {
    flex-direction: row-reverse;
  }
  .nav-item {
    text-align: end;
  }
  nav.navbar.navbar-expand-lg.p-0 {
    display: flex;
    justify-content: end;
    margin: 0 -17px 0 0;
  }

  .header-button {
    margin-right: 29px;
  }
  .header-text {
    font-size: 12px;
  }
  .header-text .btn {
    padding: 0px 14px;
    margin: 0px 0 18px 12px;
  }
  .brand {
    flex-direction: column-reverse;
    align-items: center;
  }

  .hero-section {
    height: 38vh;
  }
  .form a.btn.btn-primary.button {
    display: block;
  }
  .form input {
    width: 100%;
  }
  .service-content {
    padding: 0 19px;
  }
  .typewriter {
    font-size: 35px;
  }
  .typewriter2 {
    font-size: 31px;
  }
  .top-content p {
    font-size: 32px;
  }
  .logos img {
    width: 76px;
  }
  .logos {
    margin: 143px 0 0 -29px;
  }
  .carrier h3 {
    text-align: center;
    font-size: 39px;
    padding-top: 25px;
  }
  .carrier h1 {
    font-size: 34.5769px;
  }
  .arrow img {
    left: 46%;
    top: 247px;
    transform: rotate(90deg);
    width: 69%;
  }
  .arrow-down img {
    left: -12%;
    top: -46px;
    transform: rotate(90deg);
    width: 69%;
  }
  .arrow-down2 img {
    left: -51%;
    transform: rotate(90deg);
    width: 69%;
  }

  .shipping-heading img {
    margin-left: 10px;
    width: 175px;
  }
  .boxes {
    padding: 49px 0;
  }
  .box1 {
    margin-top: -83px;
    padding: 30px 0;
  }
  .box1 h2 {
    font-size: 19px;
  }
  .box1 p {
    font-size: 16px;
  }
  .real-time h2 {
    font-size: 24px;
    line-height: 1;
  }
  .buttons a {
    margin: 0px 14px;
    font-size: 8px;
    padding: 7px;
  }
  .e-commerce {
    padding-top: 12.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .img-container a.btn {
    left: 68%;
    font-size: 10px;
  }
  #item2 .list {
    margin-top: 62px;
    margin-bottom: 0;
  }
  #item3 img.rect {
    margin-top: 58px;
  }
  .img-container {
    top: -39px;
  }
  .blue-container .container {
    margin-bottom: -63px;
  }
  .testimoinal p {
    font-size: 13.8px;
    line-height: 20px;
  }
  .slick-slide h3 {
    font-size: 16px;
  }
  .slick-list {
    padding-top: 0% !important;
    padding-bottom: 10% !important;
    padding-left: 17% !important;
  }
  .cards .slick-current {
    transform: translate3d(0, -48px, 17px);
  }

  .signup-banner {
    height: 143px !important;
    text-align: center;
  }
  .signup-banner h2 {
    font-size: 21px;
  }
  .sigup img {
    top: 79px;
  }
}

@media (max-width: 450px) {
  .form {
    min-width: 350px;
  }
  .list li {
    font-size: 17.8px;
    padding: 3px;
  }
  .top-content {
    margin-top: 12px;
  }
  br {
    display: none;
  }
  .form input {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  .bta {
    width: 48%;
    border-radius: 0 !important;
    margin-left: 6px;
  }
  .contact img {
    width: 23px !important;
    margin-right: -6px !important;
  }
  .form a.btn.btn-primary.button {
    display: -webkit-inline-box;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form .btn {
    font-size: 9.75px;
    padding: 9px 21px;
    max-width: 46%;
  }
  .form input {
    width: 75%;
    height: 32px;
  }
  .form-block input {
    font-size: 10px;
    width: 50%;
    height: 35px;
    padding-left: 17px;
    margin-bottom: 16px;
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.19);
    box-shadow: 1px 1px 8px rgb(0 0 0 / 11%);
    border-radius: 3px;
    margin: 13px 6px 10px 0;
  }
  .dim-block input {
    font-size: 10px;
    width: 25%;
    height: 35px;
    padding-left: 13px;
    margin-bottom: 16px;
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.19);
    box-shadow: 1px 1px 8px rgb(0 0 0 / 11%);
    border-radius: 3px;
    margin: 0px 6px 6px 0;

  }
  .full {
    width: 100%;
  }
  .half {
    width: 50%;
    display: flex;
  }
  .form2 .shipping .btn {
    border: none;
    color: #131466;
    padding: 9px 12px;
    background: rgba(12, 105, 243, 0.29);
    box-shadow: 1px 1px 8px rgb(0 0 0 / 11%);
    border-radius: 3px;
    font-size: 6.75px;
  }
  .form2 .btn {
    font-size: 10px;
    padding: 8px 11px;
    margin: 5px 6px 10px 0;
  }

  .form .shipping .btn {
    font-size: 6.75px;
    padding: 8px 11px;
  }
  .form.form .btn {
    font-size: 10px;
    padding: 10px 11px;
  }
  .trackbtn {
    padding: 8px 11px !important;
    margin-top: 1px;
  }
  .form .location .btn {
    font-size: 6.75px;
    padding: 8px 11px;
  }

  input.form-block {
    font-size: 9.62px;
    width: 154px;
    height: 35px;
    padding-left: 17px;
    margin-bottom: 16px;
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.19);
    box-shadow: 1px 1px 8px rgb(0 0 0 / 11%);
    border-radius: 3px;
    margin: 13px 14px 10px 0;
  }
  label {
    margin: 4px 7px;
  }
  .brand {
    flex-direction: row;
    align-items: center;
  }

  a.navbar-brand {
    margin-left: 3px;
  }

  .butons a {
    padding: 6px 7px;
    margin: 15px 8px 15px 9px;
    text-decoration: none;
    border-radius: 2px;
    font-size: xx-small;
  }
  .butons {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 0 0px;
  }
  .butons .btn {
    display: flex;
  }
  .butons a {
    display: none;
  }
  .menu-section {
    flex-direction: row-reverse;
  }
  .contact span {
    color: #131466;
    margin: 0 0 0 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 10px;
  }
  .one {
    width: 4.333333% !important;
    margin-left: 28.7% !important;
  }
  .two {
    width: 23.333333% !important;
  }
  .three {
    width: 9.333333% !important;
    margin-left: -36px !important;
  }
  .butons .link {
    margin: 15px 0px;
  }
  .slick-track {
    top: 75px;
    left: -38px;
  }

  .pb-4 {
    padding-bottom: 0 !important;
  }

  .header-text {
    font-size: 8px;
  }
  .buttons .set1 {
    font-size: 10px;
    padding: 7px;
    margin: 5px;
    border-radius: 0;
  }
  .e-commerce {
    padding-top: 2.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .e-commerce_open {
    padding-top: 12.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .ecom-list {
    padding-top: 0rem !important;
  }
  .bolt_int_sec {
    margin-top: -40px !important;
  }
  .butons .set1 {
    /* margin: 0px 6px; */
    font-size: 10px;
    /* padding: 11px; */
  }
  .butons .set2 {
    /* margin: 0px 6px; */
    font-size: 8px;
    /* padding: 11px; */
  }
  .header-text .btn {
    font-size: 10px;
  }
  .contact {
    display: flex;
    /* justify-content: space-between; */
    margin: 0 0px;
  }
  .hero-section {
    height: 36vh;
    background-position: right 89px, left 27px, left 4px;
    background-repeat: no-repeat;
    background-size: 88%, 84%, 127%;
  }
  .typewriter {
    font-size: 16px !important;
  }
  .typewriter2 {
    font-size: 16px !important;
  }
  .button-top {
    margin-top: 11px;
    margin-bottom: -100px;
    margin-left: 27px;
  }
  .top-content p {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: -27px;
    padding: 0;
  }
  .autotext {
    margin-top: 0px !important;
  }
  .top-content .btn {
    font-size: 12.99px;
    line-height: 18px;
    padding: 10px 27px;
    margin: 9px;
  }
  .top-content svg {
    font-size: 10.99px;
    line-height: 17px;
    /* padding: 4px 4px; */
    margin-right: 3px;
  }
  .top-content img.truck-img {
    margin-left: 47%;
    margin-top: 3px;
    width: 36%;
  }
  .topq {
    margin-top: 11.5rem !important;
  }
  .blue-container .buttonss a {
    margin: 10px 15px;
    padding: 3px 19px;
  }
  .logos img {
    width: 70px;
  }
  .logos {
    margin: 0px 0 0px 30px;
  }
  .logo p {
    font-size: 13px;
  }
  .policy a {
    text-decoration: none;
    color: #131466;
    padding: 0 21px;
    font-weight: 500;
    font-size: 11px;
  }
  .footer ul li {
    font-size: 13px;
  }
  .blue-container p {
    font-size: 22px;
  }
  .blue-container h2 {
    font-size: 20px;
  }
  .blue-container .container {
    padding: 20px 0 10px 0;
  }

  .carrier h3 {
    text-align: center;
    font-size: 39px;
    padding-top: 25px;
  }
  .carrier h1 {
    font-size: 30px;
  }
  #item1 .item1-img {
    margin-left: 14px;
  }
  .shipping-heading img {
    margin-left: 10px;
    width: 178px;
    height: 34px;
  }
  .boxes {
    padding: 49px 0;
  }
  .box1 {
    margin-top: -83px;
    padding: 30px 0;
  }
  .box1 p {
    font-size: 16px;
  }
  .real-time h2 {
    font-size: 24px;
    line-height: 1;
  }
  .blue-container {
    position: relative;
    top: 72px;
  }
  .blue-container .container {
    margin-bottom: -71px;
    margin-top: -124px;
  }
  .testimoinal p {
    font-size: 10.8px;
    margin: -9px 0;
  }
  /* .slick-slide.slick-current.slick-active.slick-center h3 {
    font-size: 21.58px;
  }
  .slick-slide h3{
    font-size: 16px;
  }
  .slick-list{
    padding-top: 0%!important;
    padding-bottom: 10%!important;
    padding-left: 17%!important;
}
.cards .slick-current {
    transform: translate3d(0, -48px, 17px);
 
} */
  .slide img {
    width: 6rem;
    margin: 0 auto;
  }
  .testimoinal .slick-list {
    padding-top: 23% !important;
    padding-bottom: 0% !important;
    padding-left: 4% !important;
  }
  .styla img {
    width: 9rem !important;
    margin: 0 auto;
  }

  .slide {
    transform: scale(0.4);
    transition: transform 1s, opacity 1s;
    opacity: 0.5;
    width: 81px;
  }

  .activeSlide {
    transform: scale(1.1);
    opacity: 1;
    position: sticky;
    z-index: 1;
  }

  .arrow1 {
    /* background-color: #fff; */
    position: absolute;
    cursor: pointer;
    z-index: 10;
  }

  .arrow svg {
    transition: color 300ms;
  }

  .arrow svg:hover {
    color: #68edff;
  }

  .next {
    right: 0%;
    top: 50%;
  }

  .prev {
    left: 0%;
    top: 50%;
  }

  .card {
    max-width: 200px !important;
  }
  /*  */
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    justify-content: center;
    --bs-card-spacer-y: 0rem;
    --bs-card-spacer-x: 0rem;
  }
  .cards .slick-slide img {
    display: block;
    max-width: 93px;
    margin-left: -72px;
    margin-top: 53px;
  }
  .card py-5 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .card-text {
    font-weight: 400;
    font-size: 6px;
    line-height: 6px;
    color: #000000;
  }
  .card-title {
    font-weight: 500;
    font-size: 9px;
    line-height: 9px;
    color: #000000;
  }
  #item3 img.rect {
    margin-top: 4px;
  }
  /* .card-body{
    margin:0;
} */
  
  .signup-banner {
    height: 143px !important;
    text-align: center;
  }

  .sigup img {
    top: 74px;
  }
  .any-form .btn {
    padding: 9px;
    font-size: 12px;
    margin-top: 13px;
  }
  ul.list {
    margin: 0 10px;
  }
  .testimoinal h1 {
    padding: 17px 0;
  }
  .shipping h2 {
    font-size: 18px;
  }
  .asking h1 {
    font-size: 42px;
  }
  .logo img {
    max-width: 78%;
    margin-left: 25px;
  }
  .icons {
    margin-top: 12px;
    padding-right: 2rem !important;
  }
  .footer-menu .col-md-3 {
    margin-left: -1px;
    padding: 0;
  }
  .real-time {
    padding-top: 189px;
  }
  .card-body button {
    margin-top: -15px;
    font-size: 6.554px;
  }
  .card-body {
    margin: 0;
  }
  
 
  hr {
    margin: 0 !important;
  }
}

@media (max-width: 414px) {
  .form {
    min-width: 300px;
  }
  .top-content .typewriter,
  .top-content .typewriter2 {
    font-size: 28px;
  }
  .form input {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  .bta {
    width: 48%;
    border-radius: 0 !important;
    margin-left: 6px;
  }
  .contact img {
    width: 23px !important;
    margin-right: -6px !important;
  }
  .form a.btn.btn-primary.button {
    display: -webkit-inline-box;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form .btn {
    font-size: 9.75px;
    padding: 9px 21px;
  }
  .e-commerce h2 {
    margin-top: 70px;
  }
  .form input {
    width: 75%;
    height: 33px;
  }
  .form-block input {
    font-size: 9.62px;
    width: 50%;
    height: 35px;
    padding-left: 17px;
    margin-bottom: 16px;
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.19);
    box-shadow: 1px 1px 8px rgb(0 0 0 / 11%);
    border-radius: 3px;
    margin: 13px 6px 10px 0;
  }
  .dim-block input {
    font-size: 10px;
    width: 25%;
    height: 35px;
    padding-left: 13px;
    margin-bottom: 16px;
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.19);
    box-shadow: 1px 1px 8px rgb(0 0 0 / 11%);
    border-radius: 3px;
    margin: 0px 6px 6px 0;
  }
  .full {
    width: 100%;
  }
  .half {
    width: 50%;
    display: flex;
  }
  .form2 .shipping .btn {
    border: none;
    color: #131466;
    padding: 9px 12px;
    background: rgba(12, 105, 243, 0.29);
    box-shadow: 1px 1px 8px rgb(0 0 0 / 11%);
    border-radius: 3px;
    font-size: 6.75px;
  }
  .form2 .btn {
    font-size: 10px;
    padding: 8px 11px;
    margin: 5px 6px 10px 0;
  }

  .form .shipping .btn {
    font-size: 6.75px;
    padding: 8px 11px;
  }
  .form.form .btn {
    font-size: 10px;
    padding: 10px 11px;
  }
  .trackbtn {
    padding: 8px 11px !important;
    margin-top: 1px;
  }
  .form .location .btn {
    font-size: 6.75px;
    padding: 8px 11px;
  }

  input.form-block {
    font-size: 9.62px;
    width: 154px;
    height: 35px;
    padding-left: 17px;
    margin-bottom: 16px;
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.19);
    box-shadow: 1px 1px 8px rgb(0 0 0 / 11%);
    border-radius: 3px;
    margin: 13px 14px 10px 0;
  }
  label {
    margin: 4px 7px;
  }
  .brand {
    flex-direction: row;
    align-items: center;
  }

  a.navbar-brand {
    margin-left: 3px;
  }
  /* .navbar-brand .img-fluid {
          max-width: 100%;
          height: auto;
      } */
  .butons a {
    padding: 6px 7px;
    margin: 15px 8px 15px 0px;
    text-decoration: none;
    border-radius: 2px;
    font-size: xx-small;
  }
  .butons {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 0 0px;
  }
  .butons .btn {
    display: flex;
  }
  .butons a {
    display: none;
  }
  .menu-section {
    flex-direction: row-reverse;
  }
  .contact span {
    color: #131466;
    margin: 0 0 0 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 10px;
  }
  .one {
    width: 4.333333% !important;
    margin-left: 28.7% !important;
  }
  .two {
    width: 23.333333% !important;
  }
  .three {
    width: 9.333333% !important;
    margin-left: -36px !important;
  }
  .butons .link {
    margin: 15px 0px;
  }
  .slick-track {
    top: 75px;
    left: -38px;
  }

  .pb-4 {
    padding-bottom: 0 !important;
  }

  .header-text {
    font-size: 8px;
  }
  .buttons .set1 {
    font-size: 10px;
    padding: 7px;
    margin: 5px;
    border-radius: 0;
    min-width: fit-content;
  }
  .e-commerce {
    padding-top: 3.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .e-commerce_open {
    padding-top: 10.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .ecom-list {
    padding-top: 0rem !important;
  }
  .list li {
    font-size: 16.8px;
    padding: 3px;
  }
  .bolt_int_sec {
    margin-top: -40px !important;
  }
  .butons .set1 {
    /* margin: 0px 6px; */
    font-size: 10px;
    min-width: fit-content;
    /* padding: 11px; */
  }
  .butons .set2 {
    /* margin: 0px 6px; */
    font-size: 8px;

    /* padding: 11px; */
  }
  .header-text .btn {
    font-size: 10px;
  }
  .contact {
    display: flex;
    /* justify-content: space-between; */
    margin: 0 0px;
  }
  .hero-section {
    height: 36vh;
    background-position: right 89px, left 27px, left 4px;
    background-repeat: no-repeat;
    background-size: 88%, 84%, 127%;
  }
  .typewriter {
    font-size: 22px;
  }
  .typewriter2 {
    font-size: 28px;
  }
  .button-top {
    margin-top: 11px;
    margin-bottom: -100px;
    margin-left: 11px;
  }
  .top-content p {
    font-size: 26px;
    padding: 0;
    line-height: 32px;
    margin-bottom: -31px;
  }
  .top-content .btn {
    font-size: 10px;
    line-height: 18px;
    padding: 10px 20px;
    margin: 9px;
  }
  .top-content svg {
    font-size: 10.99px;
    line-height: 17px;
    /* padding: 4px 4px; */
    margin-right: 3px;
  }
  .top-content img.truck-img {
    margin-left: 52%;
    /* margin-bottom: 11px; */
    width: 36%;
    margin-top: -1px;
  }
  .topq {
    margin-top: 15.5rem !important;
  }
  .blue-container .buttonss a {
    margin: 10px 15px;
    padding: 3px 19px;
  }
  .logos img {
    width: 70px;
  }
  .logos {
    margin: 0px 0 0px 11px;
  }
  .logo p {
    font-size: 13px;
  }
  .policy a {
    text-decoration: none;
    color: #131466;
    padding: 0 21px;
    font-weight: 500;
    font-size: 11px;
  }
  .footer ul li {
    font-size: 13px;
  }
  .blue-container p {
    font-size: 22px;
  }
  .blue-container h2 {
    font-size: 20px;
  }
  .blue-container .container {
    padding: 20px 0 10px 0;
  }

  .carrier h3 {
    text-align: center;
    font-size: 39px;
    padding-top: 25px;
  }
  .carrier h1 {
    font-size: 30px;
  }
  #item1 .item1-img {
    margin-left: 14px;
  }
  .shipping-heading img {
    margin-left: 2px;
  }
  .boxes {
    padding: 49px 0;
  }
  .box1 {
    margin-top: -83px;
    padding: 30px 0;
  }
  .box1 p {
    font-size: 16px;
  }
  .real-time h2 {
    font-size: 24px;
    line-height: 1;
  }
  .blue-container {
    position: relative;
    top: 72px;
  }
  .blue-container .container {
    margin-bottom: -71px;
    margin-top: -124px;
  }
  .testimoinal p {
    font-size: 10.8px;
    margin: -9px 0;
  }
  /* .slick-slide.slick-current.slick-active.slick-center h3 {
      font-size: 21.58px;
    }
    .slick-slide h3{
      font-size: 16px;
    }
    .slick-list{
      padding-top: 0%!important;
      padding-bottom: 10%!important;
      padding-left: 17%!important;
  }
  .cards .slick-current {
      transform: translate3d(0, -48px, 17px);
   
  } */
  .slide img {
    width: 6rem;
    margin: 0 auto;
  }
  .testimoinal .slick-list {
    padding-top: 23% !important;
    padding-bottom: 0% !important;
    padding-left: 4% !important;
  }
  .styla img {
    width: 9rem !important;
    margin: 0 auto;
  }

  .slide {
    transform: scale(0.4);
    transition: transform 1s, opacity 1s;
    opacity: 0.5;
    width: 81px;
  }

  .activeSlide {
    transform: scale(1.1);
    opacity: 1;
    position: sticky;
    z-index: 1;
  }

  .arrow1 {
    /* background-color: #fff; */
    position: absolute;
    cursor: pointer;
    z-index: 10;
  }

  .arrow svg {
    transition: color 300ms;
  }

  .arrow svg:hover {
    color: #68edff;
  }

  .next {
    right: 0%;
    top: 50%;
  }

  .prev {
    left: 0%;
    top: 50%;
  }

  .card {
    max-width: 200px !important;
  }
  /*  */
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    justify-content: center;
    --bs-card-spacer-y: 0rem;
    --bs-card-spacer-x: 0rem;
  }
  .cards .slick-slide img {
    display: block;
    max-width: 93px;
    margin-left: -72px;
    margin-top: 53px;
  }
  .card py-5 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .card-text {
    font-weight: 400;
    font-size: 6px;
    line-height: 6px;
    color: #000000;
  }
  .card-title {
    font-weight: 500;
    font-size: 9px;
    line-height: 9px;
    color: #000000;
  }
  #item3 img.rect {
    margin-top: 4px;
  }
 
   .signup-banner {
    height: 143px !important;
    text-align: center;
  }
  .signup-banner h2 {
    font-size: 21px;
    padding-right: 0 !important;
    margin-bottom: 15px;
  }
  .sigup img {
    top: 74px;
  }
  .any-form .btn {
    padding: 9px;
    font-size: 12px;
    margin-top: 13px;
  }
  ul.list {
    margin: 0 10px;
  }
  .testimoinal h1 {
    padding: 17px 0;
  }
  .shipping h2 {
    font-size: 18px;
  }
  .asking h1 {
    font-size: 42px;
  }
  .logo img {
    max-width: 78%;
    margin-left: 25px;
  }
  .icons {
    margin-top: 12px;
    padding-right: 2rem !important;
  }
  .footer-menu .col-md-3 {
    margin-left: -19px;
    padding: 0;
  }
  .real-time {
    padding-top: 189px;
  }
  .card-body button {
    margin-top: -15px;
    font-size: 6.554px;
  }
  .card-body {
    margin: 0;
  }
 

  hr {
    margin: 0 !important;
  }
}
@media (max-width: 320px) {
  .menus {
    padding-top: 14px;
  }

  a.navbar-brand {
    margin-left: -1px;
  }
  .contact span {
    margin: -1px 0 9px 0;
    font-size: 10px;
  }
  .contact img {
    margin-left: -15px;
    max-width: 31px;
    margin-right: -8px;
  }
  nav.navbar.navbar-expand-lg.p-0 {
    display: flex;
    justify-content: end;
    margin: 0 -46px 0 0;
  }
 
  .header-text .btn {
    margin: -1px -30px 34px 7px;
    padding: 2px 6px;
    font-size: 9px;
  }
  .header-text {
    margin-left: 16px;
  }
  .butons {
    margin: 0 0 0 -23px;
  }
  .hero-section {
    height: 38vh;
  }
  .top-content {
    padding: 15px 0 0 0;
    margin-top: -11px;
  }
  .top-content p {
    font-size: 24px;
    left: 0;
  }
  .top-content .btn {
    font-size: 8px;
  }
  .top-content img.truck-img {
    margin-left: 22%;
    width: 58%;
  }
  .top-content p img {
    left: -45px;
    margin-left: 0;
  }
  .typewriter {
    font-size: 23px;
  }
  .typewriter2 {
    font-size: 23px;
  }
  .animated-text {
    margin-top: 1px;
    margin-bottom: -48px;
  }
  .logos {
    margin: 0px -10px 0 -9px;
  }
  .logos img {
    width: 59px;
    margin-top: 8px;
  }
  .carrier h3 {
    text-align: center;
    font-size: 31px;
    padding-top: 25px;
  }
  .carrier h1 {
    font-size: 25px;
  }
  .service-content {
    padding: 0 19px;
    font-size: 16px;
  }
  .list li {
    font-size: 14.8px;
  }
  .buttons .set1 {
    font-size: 6px;
  }
  .butons .set1 {
    font-size: 6px;
  }
  .butons .set2 {
    font-size: 6px;
  }
  .e-commerce {
    margin-top: 169px;
  }
  .e-commerce h2 {
    font-size: 15px;
  }
  .e-commerce p {
    font-size: 14.58px;
    padding-top: 11px;
  }
  .arrow-down2 img {
    left: -62%;
  }
  .buttons {
    padding: 0 20px;
  }
  .buttons .set1 {
    padding: 4px 15px;
    margin: 0 3px;
  }
 
  .shipping-heading img {
    margin-left: 10px;
    width: 133px;
  }
  .shipping-heading h3 {
    font-size: 20.5816px;
  }
  .shipping h2 {
    font-size: 14px;
  }
  .box1 p {
    font-size: 12px;
  }
  .blue-container p {
    font-size: 19px;
  }
  .blue-container h2 {
    font-size: 16px;
  }
  .blue-container .buttonss a {
    margin: 0 5px 11px 0px;
    padding: 4px 19px;
    font-size: 10px;
  }
  .blue-container {
    top: 44px;
  }
  .real-time h2 {
    font-size: 24px;
  }
  .real-time p {
    font-size: 14.8px;
  }
  .asking h1 {
    font-size: 35px;
  }
  .asking p {
    font-size: 22.1532px;
  }
  .input-field input {
    font-size: 14px;
  }
  .asking button.btn.btn-primary {
    font-size: 8px;
  }
  .signup-banner h2 {
    font-size: 17px;
  }
  .sigup img {
    margin-left: 5px;
    top: 68px;
  }
  .any-form .btn {
    font-size: 9px;
  }
  input.input {
    padding: 6px 0 4px 39px;
    font-size: 10px;
  }
  .get-in-touch a {
    font-size: 10px;
  }
  .sigup-email button {
    font-size: 9px;
  }
  .icons {
    margin-top: 12px;
  }
  .icons .ins {
    padding: 0 7px;
    font-size: 40px;
    color: #131466;
    margin-left: 11px;
  }
  .footer-base p {
    font-size: 9px;
  }
  .policy a {
    font-size: 7px;
  }
}



/* customization */

.btn{
  border-radius: 0 !important;
}


/*styling HSM*/

/* hero banner landing page*/
.row.top-hero-sec {
  background-color: #131466;
background-image:  url(../images/bannerimg.png);
background-repeat: no-repeat;
  padding: 40px 0 110px 0;
  background-position: center;
}

.row.top-hero-sec h2.heading-content {
  text-align: center;
  color: #fff;
  font-size: 40px;
}

.row.top-hero-sec h2.heading-content span.color-side {
  color: #131466;
  display: block;
  background: #fff;
  max-width: fit-content;
  margin: 0 auto;
  font-weight: 700;
  font-size: 44px;
  margin-top: 13px;
  padding: 2px 6px;
}

section.hero-section.container-fluid .form2.shadow {
  margin-top: -70px;
}
.form-box-wrap {
  margin-top: -70px;
}

.form-box-wrap form {
  background: #fff;
  padding: 0 20px 20px 20px;
  border-radius: 0 2px 0 0;
}

.form-box-wrap button {
  margin: 0;
  max-width: fit-content;
}

.form-box-wrap button.bta.btn.btn-light{
  background: transparent;
  border-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.form-box-wrap button.bta.btn.btn-primary {
  background: #fff;
  border-color: #fff;
  color: #131466;
  font-size: 16px;
  font-weight: 700;
  border-radius: 2px 2px 0px 0px !important;
}
.form-block.estimation-form {
  display: flex;
  align-items: flex-start;
  padding: 10px 0 20px 0;
}

.form-block.estimation-form span.area-est input {
  width: 100%;
}

.form-block.estimation-form label {
  margin: 0 !important;
  width: 100%;
  font-weight: 600;
}

.form-block.estimation-form input {
  margin: 0;
}

.form-block.estimation-form span.area-est {
  width: 25%;
  padding-right: 15px;
}

.form-block.estimation-form span.dimention-est {
  width: 35%;
  display: flex;
  flex-wrap: wrap;
}

.form-block.estimation-form span.weight-est {
  width: 15%;
  padding-left: 15px;
}

.form-block.estimation-form span.weight-est input {
  width: 100%;
}

.form-block.estimation-form span.dimention-est input {
  width: 32.33%;
  margin-right: 1%;
}
