.review__item_wrap {
  width: 100%;
  margin-top: 20px;
}
.review__item_body {
  width: 100%;
  display: flex;
}
.review__item_reviewerinfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
}
.review__item_reviewerimg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.review__item_reviewerimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.review__item_reviewername {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
  color: #0c69f3;
  margin-top: 5px;
}
.review__item_reviewertype {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  text-decoration-line: underline;
  color: #131466;
}
.review__item_reviewerlocation {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.review__item_reviewerlocationtxt {
  margin-left: 7px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-transform: capitalize;
  color: #5e5f63;
}
.review__item_rightsec {
  width: 200px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}
.review__item_rightsecdate {
}
.review__item_rightsecstars {
  align-self: flex-end;
  position: absolute;
  bottom: 0;
}
.review__item_rightsecstar {
  margin-left: 5px;
  width: 22px;
}
.review__item_reviewinfo {
  flex: 1;
}
.review__item_reviewheading {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-transform: capitalize;
  color: #000000;
  margin-top: 20px;
}
.review__item_reviewdesc {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  color: #858383;
  margin-top: 15px;
}
.review__item_reviewimages {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.review__item_reviewimage {
  width: 150px;
  height: 100px;
  border-radius: 5px;
  overflow: hidden;
  background-color: lightgray;
  margin-top: 15px;
  margin-right: 10px;
}
.review__item_reviewimage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.review__item_footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.64);
}
.review__item_footertxt {
  margin-right: 15px;
}
.review__item_footerthmb {
  display: flex;
  align-items: center;
  width: 50px;
  justify-content: space-evenly;
  cursor: pointer;
}
.review__item_footerthmbcount {
  font-size: 15px;
  margin-top: 2px;
}
@media (max-width: 750px) {
  .review__item_body {
    flex-direction: column;
    padding: 3%;
  }
  .review__item_reviewerinfo {
    width: 100%;
  }
  .review__item_rightsec {
    order: 2;
    height: 30px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .review__item_reviewinfo {
    order: 3;
  }
  .review__item_rightsecstars {
    position: static;
  }
  .review__item_reviewheading {
    font-size: 16px;
  }
  .review__item_reviewdesc {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #858383;
    margin-top: 15px;
  }
  .review__item_reviewimage {
    width: 80px;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;
    background-color: lightgray;
    margin-top: 15px;
    margin-right: 10px;
  }
}
