.footer_top_banner{
    height: 20px !important;
}

.footersection__nav_main_wrap {
    display: flex;
    padding: 0 30px;
}

.footersection__nav_col_main {
    width: 25%;
}

ul.footersection__nav_list {list-style: none;padding: 0;}

.footersection__nav_col_main h2 {
    color: #131466;
    font-size: 18px;
    font-weight: 700;
}

ul.footersection__nav_list li a {
    color: #484848;
    text-decoration: none;
    width: fit-content;
}

ul.footersection__nav_list li {
    font-size: 14px;
    font-weight: 400;
    list-style: none;
    margin: 0;
    padding: 5px 0;
}

.footersection__logo p {
    font-size: 14px;
    padding-left: 0;
}

.newsletter__main_wrap {
    margin-top: -80px;
}


ul.footersection__nav_list li a:hover, ul.footersection__nav_list li a.active.nav-link {
    color: #0a58ca;
}

.getintouch__main_wrap a {
    display: inline-flex;
    align-items: center;
    color: #131466;
    font-size: 14px;
    margin-left: 12px;
}

.getintouch__main_wrap h2 {
    color: #131466;
    font-size: 18px;
    font-weight: 700;
}

.getintouch__main_wrap a img {
    margin-right: 7px;
}

.socialmedia__wrap_col {
    display: flex;
    justify-content: flex-end;
}

.socialmedia__wrap {
    padding: 0 50px;
}


.socialmedia__wrap_col .ins {
    color: #131466;
    font-size: 40px;
    margin-left: 11px;
    padding: 0 7px;
}

.footercopypolicy__wrap {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #484848;
    color: #131466;
    font-weight: 500;
    padding-top: 15px;
    margin-top: 15px;
    margin-bottom: 50px;
    width: 97%;
    margin-left: auto;
    margin-right: auto;
}

.footercopypolicy__policypages a {
    color: #131466;
    font-size: 15px;
    font-weight: 500;
    padding:0 0px 0 18px;
    text-decoration: none;
}

@media only screen and (max-width: 600px) {
    .footersection__nav_main_wrap {
        flex-wrap: wrap;
        column-gap: 30px;
    }
    
    .footersection__nav_col_main {
        width: calc(45% - 30px);
    }
    
    ul.footersection__nav_list h2 {
        font-size: 19px;
    }
    
    .footercopypolicy__wrap {
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .footercopypolicy__policypages a {
        padding: 0;
    }
    
    .footercopypolicy__policypages {
        display: flex;
        flex-wrap: wrap;
        column-gap: 15px;
    }
    
    .footercopypolicy__copylist p {
        margin-bottom: 2px;
    }
    
    .socialmedia__wrap_col {
        justify-content: center;
    }
    
    .footersection__nav_col_main:nth-child(odd) {
        width: 53%;
    }
    footer .navbar-brand {
        white-space: break-spaces;
    }
}