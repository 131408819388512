.add__review_modal {
  background-color: white;
  position: relative;
  border-radius: 5px;
  padding: 25px 35px;
}
.add__review_modalheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 18.5961px;
  line-height: 26px;
}
.add__review_modalstars {
  margin: 15px 0;
}
.add__review_modalstar {
  margin-right: 5px;
  width: 25px;
}
.add__revmodal_formitem {
  width: 100%;
  margin-bottom: 15px;
}
.add__revmodal_formlabel {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14.5px;
  color: #121633;
}
.add__revmodal_forminput {
  width: 100%;
  border: 1px solid #dedede;
  outline: none;
  height: 35px;
  margin-top: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 2px 5px;
}
.add__revmodal_formtextarea {
  width: 100%;
  border: 1px solid #dedede;
  outline: none;
  height: 75px;
  margin-top: 8px;
  border-radius: 4px;
  padding: 2px 5px;
}
.add__revmodal_formradios {
  display: flex;
  align-items: center;
}
.add__revmodal_formradio {
  margin-right: 20px;
}
.add__revmodal_formradiolbl {
  position: relative;
  left: -15px;
}
.add__revmodal_imageuploadfield {
  display: none;
}
.add__revmodal_imageupload {
  border: 1px dashed #dedede;
  border-radius: 3px;
  width: 100px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.add__revmodal_imagetxt {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 9px;
  color: #000000;
  margin-top: 5px;
}
.add__revmodal_formcheckboxlbl {
  position: relative;
  left: -12px;
}
.add__revmodal_formcheckboxlbl a {
  color: black;
}
.add__revmodal_formsubmitbtn {
  padding: 10px 25px;
  color: white;
  background: #0c69f3;
  border-radius: 5px;
  margin-top: 15px;
  border: none;
}
.add__revmodal_formitemimgs {
  display: flex;
  align-items: center;
}
.add__revmodal_imagepreview {
  border: 1px solid #dedede;
  border-radius: 3px;
  width: 100px;
  height: 70px;
  display: flex;
  margin-right: 10px;
  position: relative;
}
.add__review_modalclose {
  position: absolute;
  right: 25px;
  top: 25px;
  background: #f5f4ff;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
}
.add__revmodal_imagepreviewclose {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: white;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 2px 2px rgba(211, 211, 211, 0.753);
  cursor: pointer;
}
.add__revmodal_imagepreviewclose > img {
  width: 14px;
  height: 14px;
}
.add__revmodal_formcheck {
  font-size: 10px;
}

@media (max-width: 500px) {
  .add__revmodal_imagepreview {
    border: 1px solid #dedede;
    border-radius: 3px;
    width: 70px;
    height: 50px;
    margin-right: 6px;
  }
  .add__revmodal_imageupload {
    width: 70px;
    height: 50px;
  }
  .add__revmodal_imageupload img {
    width: 20px;
  }
  .add__revmodal_imagetxt {
    font-size: 6px;
  }
}
