.contact__us_smap {
  width: 100%;
  object-fit: contain;
  height: 500px;
}
.contact__us_smap * {
  box-sizing: border-box;
}
.contactus__content {
  padding: 100px 5%;
}
.contactus__content_contactsec {
  width: 100%;
  display: flex;
  height: 600px;
  align-items: center;
}

.contactus__content_ctctsecleft {
  width: 40%;
  height: 100%;
  background: #131466;
  color: white;
  box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.25);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contactus__content_ctctsecright {
  width: 60%;
  height: 80%;
  background-color: white;
  box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.17);
  position: relative;
  left: -20px;
  padding: 50px 5%;
}
.contactus__content_clheading {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin: 40px 0;
}
.contactus__content_clitem {
  display: flex;
  margin-bottom: 20px;
  width: 50%;
}
.contactus__content_clileft {
  margin-right: 10px;
}
.contactus__content_icons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.contactus__content_icon {
  margin: 0 10px;
  cursor: pointer;
}
.contactus__content_csrformrow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.contactus__content_csrformfieldwrap {
  width: 47%;
  min-width: 270px;
  height: 50px;
  display: flex;
  align-items: center;
  border: 0.862069px solid #dedede;
  border-radius: 3.44828px;
  overflow: hidden;
  margin-bottom: 15px;
}
.contactus__content_csrformfieldicon {
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactus__content_csrformtxtfieldicon {
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  align-items: flex-start !important;
}
.contactus__content_csrformfield {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
}
.contactus__content_csrformfield::placeholder {
  color: #131466;
}
.contactus__content_csrformtafieldwrap {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  border: 0.862069px solid #dedede;
  border-radius: 3.44828px;
  margin-bottom: 15px;
  overflow: hidden;
}
.contactus__content_csrformbtn {
  width: 47%;
  min-width: 270px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 11px;
  background: #0c69f3;
  border-radius: 2.58621px;
  cursor: pointer;
}
.contactus__feedback_section {
  padding: 50px 0;
}
.contactus_fbsection_header {
  display: flex;
  justify-content: space-between;
}
.contactus_fbsection_headerleft {
  flex: 1;
}
.contactus_fbsection_headerltop {
  font-weight: 700;
  font-size: 25.9383px;
  line-height: 35px;
  letter-spacing: 0.02em;
  color: #133240;
}
.contactus_fbsection_headerlbottom {
  font-size: 17.8326px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #133240;
}
.contactus_fbsection_headerright {
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}
.fbsection_headerright_btn {
  border: 0.810573px solid #131466;
  border-radius: 3.24229px;
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0 10px;
  cursor: pointer;
  transition: 0.2s;
  color: #131466;
}

.fbsection_headerright_btntext {
  margin: 0 5px;
}
.fbsection_headerright_btniconreverse {
  transform: rotate(180deg);
}
.contactus_fbsection_content {
  margin-top: 60px;
}
.contact__us_smap .swiper-wrapper {
  height: 500px;
}
@media (max-width: 1100px) {
  .contactus__content_contactsec {
    flex-direction: column;
    height: auto;
  }
  .contactus__content_ctctsecleft {
    width: 100%;
    display: none;
  }
  .contactus__content_ctctsecright {
    width: 100%;
    left: 0;
  }
  .contactus__content_csrformbtn {
    margin-top: 15px;
  }
  .contactus_fbsection_headerright {
    display: none;
  }
}
@media (max-width: 600px) {
  .contactus__content_csrformfieldwrap {
    width: 100%;
    min-width: 100%;
  }
  .contactus__content_csrformbtn {
    width: 48%;
    min-width: 48%;
  }
}
