.featurebenefits__main_wrap {
    max-width: 1300px;
    margin: 0 auto 50px auto;
    display: flex;
    align-items: center;
    column-gap: 5%;
}

.featurebenefits__left_col {
    width: 50%;
    display: inline-flex;
    align-items: center;
}

.featurebenefits__right_col {
    width: 45%;
}

.featurebenefits__left_col .featurebenefits__card {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-wrap: wrap;
    padding: 40px;
    margin-bottom: 33px;
    box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.17);
    min-height: 280px;
    align-items: center;
    border: 1px solid #fff;
}

/* .featurebenefits__left_col .featurebenefits__card:hover {
    background: #e9f2ff;
    border: 1px solid #d7e6ff;
} */
.featurebenefits__left_col .featurebenefits__card h3 {
    font-size: 20px;
    color: #000;
    margin-bottom: 0;
}

.featurebenefits__left_col .featurebenefits__card h3 span {
    color: #0C69F3;
}

.featurebenefits__left_col .featurebenefits__card p {
    font-size: 16px;
}

.featurebenefits__left_col .featurebenefits__card p span {
    color: #0C69F3;
}

.featurebenefits__left_col_firstcol {
    padding-right: 33px;
}

.featurebenefits__card_heading {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
}

.featurebenefits__card_heading img {
    position: relative;
    padding-right: 9px;
}

.featurebenefits__right_col h2 {
    font-size: 42px;
    font-weight: 600;
    color: #090A5C;
    margin-bottom: 30px;
}

.featurebenefits__right_col h2 span {
    color: #0C69F3;
}

.featurebenefits__right_col p {
    font-size: 18px;
    color: #000;
    font-weight: 400;
    margin: 0;
}

.featurebenefits__right_col p span {
    color: #0C69F3;
    font-weight: 700;
}
/* 
.featurebenefits__left_col .featurebenefits__card:hover .featurebenefits__card_wrap{
    transform: rotateY(360deg);
    transition: ease all 0.8s;
    
}

.featurebenefits__left_col .featurebenefits__card .featurebenefits__card_wrap{
    transition: ease all 0.8s;
} */
@media only screen and (max-width: 767px) {
    .featurebenefits__left_col_firstcol {
        padding: 0;
    }
    
    .featurebenefits__left_col {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .featurebenefits__right_col {
        width: 90%;
        margin: 0 auto;
    }
    
    section.featurebenefits__main_wrap {
        flex-wrap: wrap-reverse;
    }
    
    .featurebenefits__right_col h2 {
        font-size: 30px;
        text-align: center;
    }
    
    .featurebenefits__right_col p {
        text-align: center;
        font-size: 16px;
        margin-bottom: 30px;
    }
}