.sort__reviews_modal {
  position: absolute;
  top: 50px;
  right: 0px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 5px 1px rgba(171, 166, 166, 0.75);
  -webkit-box-shadow: 0px 3px 5px 1px rgba(171, 166, 166, 0.75);
  -moz-box-shadow: 0px 3px 5px 1px rgba(171, 166, 166, 0.75);
  width: 250px;
  min-height: 100px;
  box-sizing: border-box;
  padding: 10px;
  cursor: default;
  z-index: 1000;
}
.sortrev__modal_heading {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #6a6a6a;
}
.sortrev__modal_tabs {
  width: 100%;
  height: 40px;
  background: #e7e7e7;
  border-radius: 2px;
  color: #969696;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;
}
.sortrev__modal_tab {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
}
.sortrev__modal_tabactive {
  background-color: white;
  color: black;
}
.sortrev__modal_content {
  padding: 10px 0;
}
.sortrev__modal_contentitem {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  cursor: pointer;
}
.sortrev__modal_contentitemact {
  background-color: lightblue;
}
.sortrev__modal_contentitemicon {
  visibility: hidden;
}
.sortrev__modal_contentitemact .sortrev__modal_contentitemicon {
  visibility: visible;
}

@media (max-width: 800px) {
  .sort__reviews_modal {
    left: 0;
  }
}
