.boltpartner__banner_wrap {
    background: #131466;
    min-height: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.boltpartner__container {
    max-width: 890px;
    text-align: center;
}

.boltpartner__heading {
    font-size: 44px;
    color: #131466;
    font-weight: 700;
    background: #fff;
    width: fit-content;
    margin: 0 auto 15px auto;
    padding: 5px 14px;
}
.boltpartner__list_filter_col label::selection {
    background: transparent;
  }
.boltpartner__bodycopy {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

.boltpartner__form_wrap {
    display: flex;
    justify-content: center;
}

form.boltpartner__form {
    width: 100%;
    position: relative;
    max-width: 660px;
}

.boltpartner__field {
    width: 100%;
    height: 40px;
    border-radius: 9px;
    text-indent: 10px;
    padding-right: 35px;
}

.boltpartner__btn_search {
    position: absolute;
    top: 8px;
    right: 10px;
}
.boltpartner__list_wrap {
    display: flex;
    align-items: flex-start;
    max-width: 1200px;
    margin: 70px auto;
    min-height: 550px;
    padding: 0 30px;
}

.boltpartner__list_filter_col {
    width: 25%;
    display: flex;
    flex-wrap: wrap;
    max-width: 160px;
}

.boltpartner__list_carrierlist_wrap {
    width: calc(100% - 160px);
    border-left: 1px solid #d9d9d9;
    padding-left: 55px;
    margin-left: 15px;
}
.boltpartner__list_logos {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;  
    column-gap: 9%;
}
.heading__label {
    font-size: 18px;
    font-weight: 500;
    display: block;
    margin: 20px 0 5px 0;
}
.boltpartner__list_filter_col label {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 9px;
    margin: 4px 0;
    font-size: 16px;
    cursor: pointer;
}

.boltpartner__list_filter_col h3 {
    font-size: 18px;
    font-weight: 500;
}
.boltpartner__carrier_logo {
    max-width: 160px;
}
.css-wsp0cs-MultiValueGeneric{
    font-size: 70%;
}
@media only screen and (max-width: 600px) {
    .boltpartner__list_wrap {
        flex-wrap: wrap;
        margin: 0px auto;
        padding: 30px;
    }
    
    .boltpartner__list_filter_col {
        width: 100%;
        max-width: 100%;
        justify-content: center;
        align-items: center;
        padding: 0 ;
    }
    
    .boltpartner__list_carrierlist_wrap {
        width: 100%;
        border: none;
        padding: 0;
        margin: 0;
    }
    
    .boltpartner__list_filter_col h3 {
        margin: 0 0 7px 0;
    }
    
    .css-b62m3t-container {
        width: 100%;
    }
    
    .boltpartner__list_logos {
        justify-content: center;
    }
    
    .css-wsp0cs-MtiValueGeneric {
        font-size: 10px;
    }
    
    .css-10wo9uf-option,.css-d7l1ni-option {
        font-size: 12px !important;
    }
    
    .css-13cymwt-control {
        box-shadow: none !important;
    }
    .boltpartner__banner_wrap {
        padding: 35px;
    }
    
    h2.boltpartner__heading {
        font-size: 35px;
    }
    
    .boltpartner__bodycopy {
        font-size: 17px;
    }
    .boltpartner__carrier_logo {
        max-width: 150px;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .boltpartner__list_wrap {
        padding: 0 40px;
    }
    .boltpartner__carrier_logo {
        max-width: 120px;
    }
}