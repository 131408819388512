.featuretab__tabcontent_wrap{
    display: none;
}
.featuretab__tabcontent_wrap.active{
    display: flex;
    align-items: center;
    padding: 15px 0;
    justify-content: space-between;
}
.featuretab__tabcontent_leftcol {
    width: 55%;
}
.featuretab__section_wrap {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 40px;
}

.featuretab__heading_wrap {
    margin-top: 80px;
    margin-bottom: 50px;
}

.featuretab__heading_wrap h2 {
    font-size: 40px;
    font-weight: 600;
}

.featuretab__tabcontent_leftcol .customcarrier__list {
    font-size: 15px;
}
.featuretab__tabcontent_leftcol .customcarrier__list img {
    width: 15px;
}
.featuretab__heading_wrap h2 span {
    color: #0F69F3;
    display: block;
}

.featuretab__heading_wrap h3 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 15px;
}

.featuretab__heading_wrap h3 span {
    color: #0C69F3;
}
.featuretab__btn_row {
    display: flex;
    justify-content: flex-start;
}

.featuretab__btn {
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    color: #767676;
    padding: 3px;
    font-size: 20px;
    margin-right: 15px;
    background: transparent;
}

.featuretab__btn.active {
    color: #0C69F3;
    font-weight: 700;
    border-bottom: 3px solid;
    border-radius: 5px 5px 0 0;
}
.featuretab__tab_wrap {
    margin-bottom: 130px;
}

.featuretab__tabcontent_rightcol {
    width: 44%;
}

.featuretab__tabcontent_rightcol img {
    width: 100%;
}
.featuretab__mobileview_wrap {
    display: none;
}

@media only screen and (max-width: 768px) {
.featuretab__tabcontent_wrap.active {
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px;
}

.featuretab__tabcontent_leftcol {
    width: 100%;
}

.featuretab__tabcontent_rightcol {
    width: 100%;
}

.featuretab__tabcontent_rightcol img {
    max-width: 100%;
}

.featuretab__btn {
    width: 100%;
}

.featuretab__btn_row {
    flex-wrap: wrap;
}

.featuretab__btn.active {
    box-shadow: 0px 0px 10px 0px #ccc;
    position: relative;
    border: 2px solid;
    border-radius: 5px;
}
.featuretab__heading_wrap h2 span {
    color: #0F69F3;
}
.featuretab__tab_wrap {
    display: none;
}
.featuretab__mobileview_wrap{
    display: block;
}
.featuretab__mobileview_wrap .featuretab__tabimg {
    width: 100%;
    max-width: 500px;
}

.featuretab__firsttab_sec {
    margin-bottom: 40px;
}

.featuretab__secondtab_sec {
    margin-bottom: 30px;
}

.featuretab__thirdtab_sec {
    margin-bottom: 40px;
}

.featuretab__mobileview_wrap h3 {
    color: #0f69f3;
    font-weight: 600;
    font-size: 27px;
    margin-bottom: 20px;
}
}



@media only screen and (max-width: 500px) {
    .featuretab__heading_wrap h2{    font-size: 30px;}
    .featuretab__section_wrap{    padding: 0 30px;}
    .featuretab__btn{    font-size: 18px;}
}
