.zerocommission__section_wrap {
    background: #E7F0FD;
}

.zerocommission__wrap {
    max-width: 1320px;
    margin: 0 auto;
    padding: 100px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.zerocommission__left_col {
    width: 60%;
}

.zerocommission__heading {
    font-size: 45px;
    color: #131466;
    font-weight: 600;
    max-width: 580px;
}

.zerocommission__bodycopy {
    font-size: 24px;
    font-weight: 600;
    margin-top: 18px;
    margin-bottom: 30px;
}

.zerocommission__list_wrap {
    padding: 0;
    list-style: none;
}

li.zerocommission__list {
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
    line-height: 1.3;
    font-size: 18px;
}

li.zerocommission__list img {
    margin-right: 14px;
    margin-top: 5px;
}


@media only screen and (max-width: 768px) {

.zerocommission__wrap {
    flex-wrap: wrap;
}

.zerocommission__wrap img {
    max-width: 100%;
}

.zerocommission__left_col {
    width: 100%;
}
.zerocommission__heading{font-size: 36px;}
.zerocommission__bodycopy{font-size: 21px;}
}