.review__pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.review__pagination .page-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.25rem;
  color: rgb(228, 225, 225);
}

.review__pagination .page-item.active span {
  background-color: rgb(238, 236, 236);
  color: #454545;
}

.review__pagination .page-link {
  background-color: transparent;
  border-radius: 50%;
  color: #0c69f3;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 30px;
  height: 30px;
  padding: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.review__pagination .page-link-navigate {
  background-color: transparent;
  border-radius: 50%;
  color: #0c69f3;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 30px;
  height: 30px;
  padding: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.review__pagination .page-link:hover {
  background-color: rgb(228, 225, 225);
  color: #0c69f3;
}
.review__pagination .disabled,
.review__pagination .disabled * {
  pointer-events: none;
  color: grey !important;
}
