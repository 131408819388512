.feedback__card_wrap {
  width: 332.88px;
  height: 236.37px;
  background: #ffffff;
  border: 0.810573px solid #e7eaec;
  border-radius: 3.24229px;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 50px;
}
.feedback__card_header {
  display: flex;
}
.feedback__card_headerleft {
  flex: 1.4;
}
.feedback__card_headerright {
  flex: 1;
}
.feedback__card_title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #133240;
  margin: 10px 0;
}
.feedback__card_desc {
  font-size: 12.9692px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #133240;
}
