.integration__pluginworks_wrap {
    max-width: 1300px;
    margin: 0 auto 70px auto;
}

.integration__pluginworks_heading {
    text-align: center;
    font-size: 40px;
    color: #131466;
    font-weight: 700;
    margin-bottom: 33px;
}

.integration__pluginworks_listing {
    padding: 0;
    list-style: none;
    display: flex;
}
.integration__pluginworks_listing li{
    width: 18%;
    padding: 0 9px;
}
.integration__pluginworks_listing li h2{
    font-size: 16px;
    text-align: center;
    margin-top: 15px;
}
.integration__pluginworks_listing li p{
    text-align: center;
    font-size: 12px;
}
.integration__pluginworks_listing li:nth-child(1) .integration__pluginworks_listing_img{
    background-color: #131466;
}

.integration__pluginworks_listing li .integration__pluginworks_listing_img{
    background-color: #fff;
    color: #fff;
    border-radius: 50%;
    width: 85px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
    box-shadow: 6px 3px 33px 0px #0000003d;
}
.rebot.justify-content-center {
    width: 100%;
    display: flex;
}

@media only screen and (max-width: 992px) {
.integration__pluginworks_listing {
    flex-wrap: wrap;
    justify-content:center
}

.integration__pluginworks_listing li {
    max-width: 180px;
    width: 100%;
}

.integration__pluginworks_heading {
    padding: 0 15px;
    font-size: 30px;
}
.queryform__left_form {
    width: 100%;
    padding: 15px 15px;
}
}

/* @media only screen and (min-width: 992px) {
.integration__pluginworks_listing li:hover img {
    transform: rotate(360deg);
    transition: ease all 0.5s;
}

.integration__pluginworks_listing li img {
    transition: ease all 0.5s;
}
} */