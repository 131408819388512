.carrzoning__section_innercontainer {
  width: 100vw;
}
.carrzoning__custom_marker {
  min-width: 186px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}
.carrzoning__custom_markerleft {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin: 5px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
.carrzoning__custom_markerright {
  padding: 7px;
}
.carrzoning__custom_markercountry {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0c69f3;
}
.carrzoning__custom_markertxt {
  font-family: "Poppins";
  flex: 1;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #808080;
}
