.about__screen_banner {
  width: 100%;
  height: 134px;
  background: #eaf1fc;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.abouts__banner_artboard {
  position: absolute;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}
.abtscreen__banner_title {
  min-width: 170px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #5a5a5a;
  z-index: 2;
}
.abtscreen__banner_titleactive {
  color: #131466;
}
.abtscreen__banner_heading {
  font-weight: 500;
  font-size: 26.17px;
  color: #131466;
  z-index: 2;
  margin-top: 5px;
  text-align: center;
}
.about__screen_storysec {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 80px 0;
}
.about__screen_storysecinnerwrap {
  width: 100%;
  max-width: 90%;
  display: flex;
  padding: 30px 3%;
}
.abtscreen_storysec_left {
  flex: 1;
  background: #0c69f3;
  color: #ffffff;
  padding: 50px 65px;
  box-sizing: border-box;
}
.abtscreen_storysec_right {
  flex: 1.5;
  display: flex;
  align-items: center;
  position: relative;
}
.abtscreen_storysec_leftheading {
  font-family: Poppins;
  font-size: 35px;
  font-weight: 600;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
}
.abtscreen_storysec_leftdesc {
  font-family: Poppins;
  font-size: 19px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.abtscreen_storysec_rightimgwrap {
  width: 100%;
  max-width: 870px;
  border-radius: 5px;
  overflow: hidden;
  filter: drop-shadow(-35px 18px 56px rgba(0, 0, 0, 0.25));
  position: relative;
  left: -50px;
}
.abtscreen_storysec_rightimg {
  width: 100%;
  object-fit: contain;
}
.abtscreen__map_imgwrap {
  width: 100%;
  padding: 5px 5%;
  display: flex;
  justify-content: center;
  height: 500px;
  overflow: hidden;
}
.abtscreen__map_img {
  height: 600px;
  max-width: 1800px;
  object-fit: contain;
  position: relative;
  bottom: 60px;
}
.abtscreen__map_facts {
  width: 100%;
  padding: 35px 5%;
  background: #e6f0ff;
}
.abtscreen__map_factsheading {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-size: 35px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: center;
  color: #454545;
}
.abtscreen__map_factsheading > span {
  color: #0c69f3;
}
.abtscreen__map_factscontent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: auto;
}
@media (max-width: 1200px) {
  .about__screen_storysecinnerwrap {
    flex-direction: column-reverse;
  }
  .abtscreen_storysec_rightimgwrap {
    left: 0;
    max-width: 100%;
    filter: none;
  }
  .abtscreen__map_imgwrap {
    overflow-y: hidden;
  }
  .abtscreen__map_img {
    height: auto;
    width: 100%;
  }
}
@media (max-width: 500px) {
  .about__screen_storysecinnerwrap {
    padding: 5px 0;
    text-align: center;
  }
  .abtscreen_storysec_left {
    padding: 20px;
  }
  .abtscreen_storysec_leftdesc {
    text-align: center;
  }
  .abtscreen__map_factscontent {
    justify-content: center;
  }
}
@media (min-width: 1600px) {
  .abtscreen_storysec_left {
    padding: 50px 95px;
  }
  .abtscreen_storysec_leftheading {
    font-size: 40px;
    line-height: 53px;
  }
  .abtscreen_storysec_leftdesc {
    font-size: 26px;
    line-height: 38px;
    letter-spacing: 0em;
  }
}
@media (min-width: 2200px) {
  .about__screen_storysecinnerwrap {
    max-width: 2200px;
  }
  .abtscreen_storysec_left {
    padding: 100px 125px;
  }
  .abtscreen_storysec_leftheading {
    font-size: 60px;
    line-height: 53px;
  }
  .abtscreen_storysec_leftdesc {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 1px;
    margin-top: 15px;
  }
  .abtscreen_storysec_rightimgwrap {
    max-width: 1150px;
    width: 1150px;
  }
}
@media (min-width: 2800px) {
  .about__screen_storysecinnerwrap {
    max-width: 2800px;
  }
  .abtscreen_storysec_left {
    padding: 100px 125px;
  }
  .abtscreen_storysec_leftheading {
    font-size: 60px;
    line-height: 53px;
  }
  .abtscreen_storysec_leftdesc {
    font-size: 36px;
    line-height: 58px;
    letter-spacing: 1px;
    margin-top: 15px;
  }
  .abtscreen_storysec_rightimgwrap {
    max-width: 1250px;
    width: 1250px;
  }
}
