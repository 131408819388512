.carrier__reviewscreen_content {
  width: 100%;
  padding: 80px 0 210px 0;
}
.abtscreen__banner_titlearrow {
  margin: 0 10px;
}
.carrierrs___content_about {
  display: flex;
  align-items: center;
  height: 650px;
  max-width: 1800px;
  margin: auto;
  padding: 0 5%;
}
.carrierrs___content_aboutleft {
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.carrierrs___content_aboutright {
  position: relative;
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
}
.carrierrs___content_aboutleftbtns {
  min-width: 60px;
  height: 320px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  box-shadow: 0px 0px 12px 0px #00000040;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
}
.carrierrs___content_aboutleftbtn {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-weight: 500;
  font-size: 26.17px;
  color: rgba(19, 20, 102, 0.85);
  border-radius: 5px;
  cursor: pointer;
}
.carrscontent__albactive {
  color: white;
  background: #0c69f3;
}
.carrsabtright__mainimg {
  height: 85%;
  max-width: 110%;
  object-fit: contain;
  position: relative;
  left: -70px;
}
.carrscontent__left_content {
  background: #0c69f3;
  color: white;
  padding: 40px 140px 40px 65px;
  margin-left: 5px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.carrscontent__left_content::-webkit-scrollbar {
  width: 0.5em;
}

.carrscontent__left_content::-webkit-scrollbar-track {
  background: transparent;
}

.carrscontent__left_content::-webkit-scrollbar-thumb {
  background: transparent;
}
.carrscontent__lcontent_heading {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0em;
  margin-bottom: 15px;
}
.carrscontent__lcontent_desc {
  font-family: Poppins;
  font-size: 19px;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.carrsabtright__logo {
  position: absolute;
  top: 5px;
  right: 0px;
  max-height: 60px;
  max-width: 150px;
  object-fit: contain;
}
.carrierrs___content_reviews {
  width: 100%;
  margin: 50px 0;
  padding: 0 5%;
}
.carrscontent__reviews_heading {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-size: 35px;
  font-weight: 600;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: center;
  color: #454545;
}
.carrscontent__reviews_options {
  width: 100%;
  border-bottom: 2px solid #767676;
  margin: 20px;
  display: flex;
  justify-content: space-between;
  user-select: none;
  flex-wrap: wrap;
}
.carrscontent__reviews_olrvs {
  min-width: 100px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 20px;
}
.carrscontent__reviews_olrvslft {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  color: #bcbcbc;
}
.carrscontent__reviews_olrvsrit {
  background: #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #464646;
  padding: 1px 10px;
  margin-top: 3px;
  margin-left: 5px;
}
.carrscontent__reviews_filter {
  min-width: 105px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 20px;
}
.carrscontent__reviews_filterlft {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  color: #bcbcbc;
  margin-right: 8px;
}
.carrscontent__reviews_filterrit {
  background: #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #464646;
  padding: 1px 10px;
  margin-top: 3px;
  margin-left: 5px;
}
.carrscontent__reviews_optactive {
  color: #0c69f3;
  border-bottom: 3px solid #0c69f3 !important;
}

.carrscontent__reviews_opttxtactive {
  color: #0c69f3;
}
.carrscontent__reviews_optionsleft {
  display: flex;
}
.carrscontent__reviews_optionsright {
  display: flex;
  flex-wrap: wrap;
}
.crcreview__filter_checkbox {
  width: 17px;
  height: 17px;
  margin-top: 6px;
  margin-right: 7px;
  cursor: pointer;
}
.carrscontent__reviews_boxs {
  width: 100%;
  padding: 40px 5%;
  max-width: 2000px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.carrscontent__reviews_boxleft {
  height: 250px;
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid lightgrey;
  border-radius: 5px;
  margin-top: 10px;
}

.carrscontent__reviews_boxleftheading {
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 600;
}
.carrscontent__reviews_boxleftdesc {
  margin-top: 5px;
  font-family: "Poppins";
  font-size: 25px;
  font-weight: 400;
  color: #858383;
}
.carrscontent__reviews_boxleftstars {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carrscontent__reviews_boxleftstar {
  margin-right: 2px;
}
.carrscontent__reviews_boxright {
  height: 250px;
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid lightgrey;
  border-radius: 5px;
  padding: 20px;
  margin-top: 10px;
}
.carrscontent__reviews_britem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.carrscontentr__britem_left {
  display: flex;
  flex: 0.5;
}
.carrscontentr__britem_leftstar {
  width: 25px;
}
.carrscontentr__britem_right {
  flex: 1;
  display: flex;
}
.carrscontentr__britem_rprogress {
  width: 85%;
  height: 25px;
  background-color: #d9d9d9;
  margin-right: 5px;
}
.carrscontentr__britem_rprogressperc {
  height: 100%;
  background-color: #0c69f3;
}
.carrscontentr__britem_rperc {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  color: #464646;
}
.carrscontent__reviews_footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
  flex-wrap: wrap;
}
.carrscontent__reviews_footerleft {
  position: relative;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  max-width: 350px;
  margin-top: 20px;
}
.carrscontent__search_field {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
}
.carrscontent__search_icon {
  margin: 0 8px;
}
.carrscontent__reviews_footerright {
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;
}
.carrscontent__reviews_footerright > * {
  cursor: pointer;
  user-select: none;
}
.carrscontent__writereview_btn {
  padding: 6px 25px;
  border-radius: 5px;
  background: #0c69f3;
  color: white;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  margin-right: 20px;
}
.carrscontent__reviews_footer {
  border-bottom: 2px solid #767676;
  padding-bottom: 25px;
}
.carrscontent__writereview_sortclsicon {
  width: 35px;
}
.carrscontent__reviews_list {
  width: 100%;
  padding: 10px 0;
  border-bottom: 2px solid #767676;
}
.carrscontent__reviews_pagination {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.carrierrs___content_zoning {
  width: 100%;
  position: relative;
  margin-top: 30px;
  background-color: #c1c1c1;
}
.carrierrs___content_zoningheading {
  width: 100%;
  text-align: center;
  padding: 30px 0 20px 0;
  font-family: Poppins;
  font-size: 35px;
  font-weight: 600;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: center;
  color: #454545;
}
.carrier__reviewscreen_contentplaceholder {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 700px;
}
.carrier__reviewscreen_contentplaceholder > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -100px;
}
.carrier__reviewscreen_cntntplaceholderimg {
  max-height: 500px;
  max-width: 90%;
  object-fit: contain;
  animation: moveUpDown 1.2s ease-in-out infinite;
}
.carrier__reviewscreen_cntntplaceholdertxt {
  width: 100%;
  text-align: center;
  font-size: 50px;
  color: lightgray;
  font-weight: 300;
  position: relative;
  top: -25px;
}
.carrier__reviewscreen_cntntplaceholdertxt > span {
  color: #3f92df91;
}
.carrier__reviewscreen_contentloaderwrap {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
}
.carrier__reviewscreen_contentloaderwrap .lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  position: relative;
  bottom: 50px;
}
.carrier__reviewscreen_contentloaderwrap .lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #e4e2e2;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.carrier__reviewscreen_contentloaderwrap .lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.carrier__reviewscreen_contentloaderwrap .lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.carrier__reviewscreen_contentloaderwrap .lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
.carrscontent__reviews_listempty {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: lightgrey;
  font-size: 25px;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}
@media (min-width: 2000px) {
  .carrscontent__lcontent_heading {
    font-size: 40px;
  }
  .carrscontent__lcontent_desc {
    font-size: 21px;
  }
}
@media (max-width: 1220px) {
  .carrierrs___content_about {
    padding: 0 2%;
    flex-direction: column-reverse;
    height: fit-content;
  }
  .carrierrs___content_reviews {
    padding: 0 2%;
  }
  .carrsabtright__mainimg {
    width: 100%;
    height: auto;
    margin: auto;
    left: 0;
  }
  .carrierrs___content_aboutright {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .carrsabtright__logo {
    position: static;
    margin: 10px 0;
  }
  .carrscontent__reviews_boxs {
    flex-direction: column;
    align-items: center;
  }
  .carrscontent__reviews_boxright {
    margin-top: 20px;
  }
}
@media (max-width: 800px) {
  .carrierrs___content_aboutleft {
    flex-direction: column;
  }
  .carrierrs___content_aboutleftbtns {
    flex-direction: row;
    width: 100%;
    height: 50px;
    margin: 10px 0;
  }
  .carrierrs___content_aboutleftbtn {
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
    width: 50%;
    height: 100%;
  }
  .carrscontent__left_content {
    padding: 40px 5%;
  }
  .carrier__reviewscreen_content {
    padding: 80px 0 210px 0;
  }
  .carrscontent__reviews_options {
    max-width: 92vw;
    width: fit-content;
    overflow-x: hidden;
    padding: 0;
  }
  .carrscontent__reviews_boxleftheading {
    font-size: 25px;
  }
  .carrscontent__reviews_boxleftdesc {
    font-size: 18px;
  }
  .carrscontentr__britem_leftstar {
    width: 15px;
  }
  .carrscontentr__britem_rprogress {
    height: 15px;
    margin-top: 2px;
    width: 75%;
    margin-left: 5px;
  }
  .carrscontent__reviews_boxleft {
    height: 200px;
  }
  .carrscontent__reviews_boxright {
    height: 200px;
  }
  .carrier__reviewscreen_cntntplaceholdertxt {
    font-size: 30px;
    padding: 0 20px;
  }
}
@media (max-width: 400px) {
  .carrscontent__lcontent_heading {
    font-size: 20px;
    text-align: center;
  }
  .carrscontent__lcontent_desc {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  .carrscontent__reviews_optionsleft {
    margin-bottom: 30px;
    border-bottom: 2px solid lightgrey;
  }
  .carrscontent__reviews_filterlft,
  .carrscontent__reviews_olrvslft {
    font-size: 14px;
  }
  .carrscontent__reviews_filterrit,
  .carrscontent__reviews_olrvsrit {
    font-size: 10px;
  }
  .crcreview__filter_checkbox {
    margin-top: 1px;
  }
  .carrier__reviewscreen_cntntplaceholdertxt {
    font-size: 20px;
  }
}
